import { VpInput, VpIcon, VpFormControl, VpIconButton } from "@vtmn-play/react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { isAlphanumeric } from "../../../../Common/utils/helpers";
import useDebounce from "../../../../Redux/Hooks/useDebounce";
import { useEffect } from "react";
import { getSerialNumberFromAPIC } from "../../../../Common/APIs/getOrderInfo";
import { setItemInfo } from "../../redux/slices/PDISlice";
const SerialNumberInput = ({
  handleInputChange,
  loading,
  setScannerConfig,
  failedAPIs,
  setLoading,
  setFailedAPIs,
  mounted,
  setMounted,
}) => {
  const {
    itemInfo: { serialNumber },
  } = useSelector((state) => state.pdi);
  const dispatch = useDispatch();

  // Debounced APIC Input
  const debouncedAPIC = useDebounce(serialNumber, 1500);
  useEffect(() => {
    async function fetchSerialNumberData() {
      setLoading((prevState) => ({ ...prevState, serialNumber: true }));
      setFailedAPIs((prevState) => ({ ...prevState, serialNumberAPI: false }));
      const { serialNumber } = await getSerialNumberFromAPIC(debouncedAPIC);
      if (!serialNumber)
        setFailedAPIs((prevState) => ({ ...prevState, serialNumberAPI: true }));
      setLoading((prevState) => ({ ...prevState, serialNumber: false }));
      dispatch(
        setItemInfo({
          serialNumber:
            serialNumber?.toString()?.padStart(12, "0") || debouncedAPIC,
        })
      );
    }
    if (mounted) {
      // The APIC should be alphanumeric and of lenght 10.Only then make APIC api call to get serial number. Else, ignore.
      if (
        debouncedAPIC &&
        debouncedAPIC?.length === 10 &&
        isAlphanumeric(debouncedAPIC)
      ) {
        fetchSerialNumberData();
      }
    } else {
      setMounted(true); // Set mounted flag to true after the first render
    }
  }, [debouncedAPIC]);

  return (
    <div className="vtmn-relative vtmn-mt-5">
      <VpFormControl required>
        <VpFormControl.Label for="serial-number">
          <FormattedMessage id="pdi.form.product-detail.serial-number" />
        </VpFormControl.Label>
        <VpInput
          className={`vtmn-w-full`}
          onChange={handleInputChange}
          placeholder="eg: 000..."
          value={serialNumber}
          name="serialNumber"
          endSlot={
            loading?.serialNumber && serialNumber ? (
              <VpIconButton
                size={"small"}
                type={"button"}
                loading={true}
                disabled={true}
                variant={"tertiary"}
              ></VpIconButton>
            ) : (
              <button
                aria-label="icon to scan."
                onClick={(e) => {
                  setScannerConfig({
                    isOpen: true,
                    scanReference: "serialNumber",
                  });
                }}
                type="button"
              >
                <VpIcon name="barcode-scan" />
              </button>
            )
          }
        />
        <VpFormControl.Helper>
          {loading?.serialNumber && serialNumber && (
            <FormattedMessage id="pdi.inspection.scan.loading-serial-number-info-loading" />
          )}
          <span className="vtmn-text-background-alert">
            {serialNumber?.length === 10 &&
              !loading?.serialNumber &&
              failedAPIs?.serialNumberAPI && (
                <FormattedMessage id="pdi.inspection.scan.wrong-apic" />
              )}
            {isAlphanumeric(serialNumber) && serialNumber?.length > 10 && (
              <FormattedMessage id="pdi.inspection.scan.loading-serial-number-warning" />
            )}
          </span>
        </VpFormControl.Helper>
      </VpFormControl>
    </div>
  );
};
export default SerialNumberInput;
