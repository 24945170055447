import { getCookie } from "../utils/cookie";
import { clearSessionAndRedirectToLogin } from "../utils/session";

export default async function logout(pisri) {
  const fedInfo = getCookie("fedInfo");
  var headers = new Headers();
  headers.append("Authorization", `Bearer ${fedInfo.access_token}`);
  headers.append("X-API-KEY", process.env.REACT_APP_X_API_KEY);
  headers.append("pisri", pisri);
  var requestOptions = {
    method: "POST",
    headers: headers,
    redirect: "follow",
  };
  await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users/me/logout`,
    requestOptions
  )
    .then((response) => response.text())
    .then(async () => {
      clearSessionAndRedirectToLogin();
    })
    .catch(() => {
      clearSessionAndRedirectToLogin();
    });
}
