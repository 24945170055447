import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const initialState = {
  responses: [],
  assets: [],
  itemInfo: {
    articleID: null,
  },
  inspectionInfo: {
    purchaseChannel: null,
    productCategory: null,
  },
  inspectionID: null,
  questionnaireStartTime: null,
};
const RentalSlice = createSlice({
  name: "rental",
  initialState,
  reducers: {
    setItemInfo: (state, action) => {
      Object.keys(action?.payload).forEach((key) => {
        state.itemInfo[key] = action?.payload[key];
      });
    },
    setInspectionInfo: (state, action) => {
      Object.keys(action?.payload).forEach((key) => {
        state.inspectionInfo[key] = action?.payload[key];
      });
    },
    setQuestionnaireStartTime: (state, action) => {
      state.questionnaireStartTime = action?.payload;
    },
    setInspectionID: (state, action) => {
      state.inspectionID = action?.payload;
    },

    addRentalResponse: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers.
      // It doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      const {
        store_product_question_id,
        userResponse: { response, notes, attachment },
        ...rest
      } = action?.payload;
      const existingObjectIndex = state.responses.findIndex(
        (obj) => obj.store_product_question_id === store_product_question_id
      );

      // Add to responses array if not present
      if (existingObjectIndex !== -1) {
        state.responses[existingObjectIndex].response = response;
        state.responses[existingObjectIndex].notes = notes;
        // state.responses[existingObjectIndex].assets = attachment;
      } else {
        state.responses.push({
          store_product_question_id,
          ...rest,
          response,
          notes,
          // attachment,
        });
      }
    },
    removeRentalResponse: (state, action) => {
      state.responses = state.responses.filter(
        (item) => item.store_product_question_id !== action?.payload
      );
    },
    resetSavedResponses: (state) => {
      state.responses = [];
    },
    addRentalAssets: (state, action) => {
      const { id, attachment } = action?.payload;
      const existingAttachmentIndex = state.assets.findIndex(
        (attachment) => attachment.id === id
      );
      if (existingAttachmentIndex !== -1) {
        state.assets.attachment[id] = attachment;
      } else {
        state.assets.push({
          id,
          attachment,
        });
      }
    },
    removeRentalAsset: (state, action) => {
      const { id } = action?.payload;
      state.assets = state.assets.filter((asset) => asset.id !== id);
    },
    resetRentalAssets: (state) => {
      state.assets = [];
    },
    resetRentalQuestionnaire: () => initialState,
  },
});

const persistConfig = {
  key: "rental",
  storage,
  version: 1,
};
export const rentalReducer = persistReducer(persistConfig, RentalSlice.reducer);
export const {
  setInspectionInfo,
  setQuestionnaireStartTime,
  setInspectionID,
  resetRentalQuestionnaire,
  setItemInfo,
  addRentalResponse,
  resetSavedResponses,
  addRentalAssets,
  removeRentalAsset,
  resetRentalAssets,
  removeRentalResponse,
} = RentalSlice.actions;
