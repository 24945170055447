export function setCookie(name, value, daysToExpire = 10) {
  const date = new Date();
  date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${JSON.stringify(value)}; ${expires}; path=/`;
}

export function getCookie(name) {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies?.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return JSON.parse(cookie.substring(name?.length + 1));
    }
  }
  return null;
}
