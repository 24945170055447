import React from "react";
import { VpAccordion } from "@vtmn-play/react";

function FAQ({ accordionItems }) {
  // NOTE -- AVOID using dangerouslySetInnerHTML. Only use dangerouslySetInnerHTML with trusted and sanitized data.
  // Here, we are getting data from phraseapp
  return (
    <div className="vtmn-p-3">
      <VpAccordion>
        {accordionItems.map((item, index) => (
          <React.Fragment key={index + 1}>
            <VpAccordion.Item value={`item-${index + 1}`}>
              <VpAccordion.ItemHeader>
                <VpAccordion.ItemHeader.Label>
                  {item.title}
                </VpAccordion.ItemHeader.Label>
                <VpAccordion.ItemHeader.Icon />
              </VpAccordion.ItemHeader>
              <VpAccordion.ItemPanel>{item.content}</VpAccordion.ItemPanel>
            </VpAccordion.Item>
            {index !== accordionItems?.length - 1 && <VpAccordion.Divider />}
          </React.Fragment>
        ))}
      </VpAccordion>
    </div>
  );
}

export default FAQ;
