import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../Reducers/reducer";

import { persistStore } from "redux-persist";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  stateReconciler: autoMergeLevel1,
});
export const persistor = persistStore(store);
export default store;
