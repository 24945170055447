import { VpInput, VpFormControl, VpIconButton } from "@vtmn-play/react";
import { FormattedMessage } from "react-intl";
import useDebounce from "../../../../Redux/Hooks/useDebounce";
import { useEffect, useRef, useState } from "react";
import { getModelIdRecommendation } from "../../../../Common/APIs/getOrderInfo";
import { useDispatch, useSelector } from "react-redux";
import { setItemInfo } from "../../redux/slices/PDISlice";
const ModelNumberInput = ({
  handleInputChange,
  mounted,
  setMounted,
  setLoading,
  setFailedAPIs,
  failedAPIs,
  loading,
}) => {
  // Flag to prevent API call on manual selection of modelID from the dropdown
  const [isManualSelection, setIsManualSelection] = useState(false);
  const [modelIDresults, setModelIDresults] = useState([]);

  const dispatch = useDispatch();
  const dropdownRef = useRef(); // To detect outside click

  const {
    itemInfo: { modelId },
    inspectionInfo: { purchaseChannel },
  } = useSelector((state) => state.pdi);

  // ------ DEBOIUNCED MODELID ------
  const debouncedModelId = useDebounce(modelId, 100);
  useEffect(() => {
    // API call should happen only if purchaseChannel is "PHYSICAL_INSPECTION".
    // For other purchaseChannels, ignore the API call.
    if (purchaseChannel !== "PHYSICAL_INSPECTION") {
      return;
    }
    async function fetchModelData() {
      setLoading((prevState) => ({ ...prevState, modelId: true }));
      setFailedAPIs((prevState) => ({ ...prevState, modelIdAPI: false }));
      const modelIdRecommendation =
        await getModelIdRecommendation(debouncedModelId);
      if (!modelIdRecommendation || modelIdRecommendation.length === 0) {
        setFailedAPIs((prevState) => ({ ...prevState, modelIdAPI: true }));
      }
      setModelIDresults(modelIdRecommendation);
      setLoading((prevState) => ({ ...prevState, modelId: false }));
    }
    if (mounted && !isManualSelection) {
      if (debouncedModelId && debouncedModelId?.length >= 4) {
        fetchModelData();
      } else {
        setFailedAPIs((prevState) => ({ ...prevState, modelIdAPI: false }));
        setModelIDresults([]);
      }
    } else {
      setMounted(true); // Set mounted flag to true after the first render
    }
    setIsManualSelection(false); // Reset flag after API call
  }, [debouncedModelId, setModelIDresults, mounted]);

  //This function is used to handle the selection of modelID from the dropdown
  const handleModelIdSelection = (item) => {
    setIsManualSelection(true); // Prevent API call on manual selection
    dispatch(
      setItemInfo({
        modelName: item.name,
        modelId: item.sku,
      })
    );
    setModelIDresults([]);
    setFailedAPIs((prevState) => ({ ...prevState, modelIdAPI: false }));
  };

  // Hide dropdown on outside click and show error message if user doesn't select any suggestion
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        if (!isManualSelection && modelIDresults.length > 0) {
          setModelIDresults([]); // Hide dropdown on outside click
          setFailedAPIs((prevState) => ({ ...prevState, modelIdAPI: true })); // Show error message on outside click
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modelIDresults, isManualSelection, setFailedAPIs]);

  return (
    <div className="vtmn-relative vtmn-mt-5" ref={dropdownRef}>
      <VpFormControl required>
        <VpFormControl.Label>
          <FormattedMessage id="pdi.form.product-detail.item-code" />
        </VpFormControl.Label>
        <VpInput
          // When you set inputmode="numeric", it tells browsers, especially on mobile devices, to display a numeric keypad instead of the standard keyboard
          inputmode="numeric"
          type="number"
          pattern="[0-9 ]*"
          className={`vtmn-w-full`}
          onChange={handleInputChange}
          placeholder="eg: 2126071"
          value={modelId}
          name="modelId"
          disabled={loading?.orderID}
          endSlot={
            loading?.modelId &&
            modelId && (
              <VpIconButton
                size={"small"}
                type={"button"}
                loading={true}
                disabled={true}
                variant={"tertiary"}
              ></VpIconButton>
            )
          }
        />

        {modelIDresults.length > 0 && (
          <ul className="modelid-recommendation-list vtmn-absolute vtmn-p-0 vtmn-mt-1 vtmn-w-full vtmn-bg-white">
            {modelIDresults.map((item) => (
              <button
                key={item.sku}
                className="modelid-recommendation-listitem vtmn-text-sm vtmn-p-1 vtmn-cursor-pointer vtmn-w-full vtmn-text-left vtmn-bg-white vtmn-my-2"
                onClick={() => handleModelIdSelection(item)}
              >
                {item.sku}
                <span className="vtmn-text-grey-dark-1 ">
                  ({item.name.substring(0, 25) + "..."})
                </span>
              </button>
            ))}
          </ul>
        )}
        {/* Display error message if no suggestions or user clicks outside */}
        {failedAPIs?.modelIdAPI && (
          <>
            <VpFormControl.Error>
              <FormattedMessage id="pdi.form.product-detail.item-code-invalid-error" />
            </VpFormControl.Error>
            <VpFormControl.Helper className="vtmn-text-xs vtmn-px-1">
              <FormattedMessage id="pdi.form.product-detail.item-code-invalid-info" />
            </VpFormControl.Helper>
          </>
        )}
      </VpFormControl>
    </div>
  );
};
export default ModelNumberInput;
