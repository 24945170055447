import { VpButton, VpFormControl, VpInput, VpIcon } from "@vtmn-play/react";
import { FormattedMessage } from "react-intl";
import NavigationBlocks from "../../../../Common/components/NavigationBlocks";
import { SUP, cycle, kayak, windsurf } from "../../../../Static/images/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  resetSavedResponses,
  setInspectionInfo,
} from "../../redux/slices/RentalSlice";
import { useAPI } from "../../../../Common/utils/APIUtils";
import {
  AppTypeIDMappers,
  CategoryIDMapper,
  NavigationBlocksMapper,
  PurchaseChannelsIDMappers,
} from "../../../../Common/Constants";
import {
  getKeyByValue,
  handleError,
  handleLoading,
} from "../../../../Common/utils/helpers";

const RentalInspectionCategory = () => {
  const dispatch = useDispatch();
  const {
    rental: {
      inspectionInfo: {
        purchaseChannel,
        productCategory: currentProductCategory,
      },
    },
    userStore: { activeAppType },
  } = useSelector((state) => state);

  const appTypeID = getKeyByValue(AppTypeIDMappers, activeAppType);
  const purchaseChannelID = getKeyByValue(
    PurchaseChannelsIDMappers,
    purchaseChannel
  );

  const { data, error, isLoading } = useAPI(
    `/api/v1/product_categories?inspection_type_id=${appTypeID}&purchase_channel_id=${purchaseChannelID}`
  );

  const handleBlockClick = ({ productCategory }) => {
    dispatch(
      setInspectionInfo({
        productCategory: productCategory,
      })
    );
    if (currentProductCategory !== productCategory) {
      dispatch(resetSavedResponses());
    }
  };

  if (!data && isLoading) return handleLoading();
  if (!data && error) return handleError();

  if (data?.items?.length) {
    const categoryMapperID = data?.items?.map(
      (item) => CategoryIDMapper[item?.["id"]]
    );

    const getNavigationBlocksData = categoryMapperID.map(
      (category_name) =>
        NavigationBlocksMapper[activeAppType]?.channels?.[purchaseChannel]
          ?.category?.[category_name]?.meta
    );
    const navigationBlocks = getNavigationBlocksData.map((item) => ({
      title: <FormattedMessage id={item.phraseKey} />,
      url: item?.redirectUrl,
      icon: <VpIcon name={item?.vpIcon} size={28} />,
      onBlockClick: () => handleBlockClick(item?.blockClickParams),
    }));

    return (
      <div className="vtmn-w-full vtmn-px-3 vtmn-my-3">
        <h1 className="vtmn-font-bold vtmn-text-2xl">
          <FormattedMessage id="rental.inspection.category.select-category" />
        </h1>
        <NavigationBlocks blocks={navigationBlocks} />
      </div>
    );
  }
  return null;
};

export default RentalInspectionCategory;
