import { VpInput, VpIcon, VpFormControl, VpIconButton } from "@vtmn-play/react";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { setItemInfo } from "../../redux/slices/PDISlice";
import { useEffect, useState } from "react";
import useDebounce from "../../../../Redux/Hooks/useDebounce";
import { extractOrderItems } from "../../../../Common/utils/helpers";

const OrderNumberInput = ({
  loading,
  setScannerConfig,
  populateFields,
  setLoading,
  setFailedAPIs,
  mounted,
  setMounted,
}) => {
  const dispatch = useDispatch();
  const [orderItems, setOrderItems] = useState([]);
  const {
    itemInfo: { orderReferenceNumber },
  } = useSelector((state) => state.pdi);

  // Debounced OrderID Input
  const debouncedOrderID = useDebounce(orderReferenceNumber, 1500);
  useEffect(() => {
    async function fetchOrderData() {
      setLoading((prevState) => ({ ...prevState, orderID: true }));
      setFailedAPIs((prevState) => ({ ...prevState, orderAPI: false }));
      const { reference_id, filtered_items } =
        await extractOrderItems(debouncedOrderID);

      setLoading((prevState) => ({ ...prevState, orderID: false }));
      dispatch(
        setItemInfo({
          orderNumber: reference_id,
          orderReferenceNumber: debouncedOrderID,
        })
      );

      if (filtered_items?.length === 0) {
        setFailedAPIs((prevState) => ({ ...prevState, orderAPI: true }));
      } else if (filtered_items?.length === 1) {
        populateFields(filtered_items[0]);
      } else if (filtered_items?.length > 1) {
        setOrderItems(filtered_items);
      }
    }
    if (mounted) {
      if (debouncedOrderID && debouncedOrderID?.length > 11) {
        fetchOrderData();
      }
    } else {
      setMounted(true); // Set mounted flag to true after the first render
    }
  }, [debouncedOrderID]);
  // Debounced OrderID Input

  const handleOrderIdChange = (e) => {
    let orderIDComponents = e?.target?.value?.trim()?.split(":");
    dispatch(
      setItemInfo({
        orderReferenceNumber: orderIDComponents[orderIDComponents.length - 1],
      })
    );
  };

  return (
    <>
      <div className="vtmn-relative vtmn-mt-5">
        <VpFormControl>
          <VpFormControl.Label>
            <FormattedMessage id="pdi.form.product-detail.order-number" />
          </VpFormControl.Label>
          <VpInput
            className={`vtmn-w-full`}
            endSlot={
              loading?.orderID && orderReferenceNumber ? (
                <VpIconButton
                  size={"small"}
                  type={"button"}
                  loading={true}
                  disabled={true}
                  variant={"tertiary"}
                ></VpIconButton>
              ) : (
                <button
                  aria-label="icon to scan."
                  onClick={(e) => {
                    setScannerConfig({
                      isOpen: true,
                      scanReference: "orderNumber",
                    });
                  }}
                  type="button"
                >
                  <VpIcon name="barcode-scan" />
                </button>
              )
            }
            onChange={handleOrderIdChange}
            placeholder="eg: GB..."
            value={orderReferenceNumber}
            name="orderNumber"
          />
          <VpFormControl.Helper>
            {loading?.orderID && orderReferenceNumber && (
              <FormattedMessage id="pdi.inspection.scan.loading-order-info-loading" />
            )}
          </VpFormControl.Helper>
        </VpFormControl>
      </div>
      {Boolean(orderItems?.length) && (
        <div className="order-info-overlay">
          <div className="order-info-popup">
            <h2 className="vtmn-text-2xl vtmn-font-bold vtmn-text-center vtmn-flex vtmn-items-center">
              <span className="vtmn-flex-1">
                <FormattedMessage id="pdi.form.order-detail.select-item" />
              </span>
              <span
                className="vtmx-close-circle-line vtmn-z-30 vtmn-w-min vtmn-text-4xl vtmn-cursor-pointer"
                onClick={() => {
                  setOrderItems([]);
                }}
              ></span>
            </h2>

            {orderItems?.map((item, index) => (
              <div className="block" key={index}>
                <div
                  className="vtmn-card vtmn-card_variant--side-image vtmn-my-2 vtmn-border vtmn-border-grey-light-3 vtmn-cursor-pointer"
                  onClick={() => {
                    populateFields(item);
                    setOrderItems([]);
                  }}
                >
                  <div className="vtmn-card_image vtmn-self-center ">
                    <img alt="product-img" src={item?.mainImage?.url} />
                  </div>
                  <div className="vtmn-card_content">
                    <span className="vtmn-card_content--body">
                      <div className="vtmn-flex vtmn-flex-col vtmn-text-xs vtmn-font-bold">
                        <span>
                          <FormattedMessage id="pdi.form.order-detail.select-item.name" />
                          : <b>{item?.title.slice(0, 20) + "..."}</b>
                        </span>
                        <span>
                          <FormattedMessage id="pdi.form.order-detail.select-item.brand" />
                          : <b>{item?.brand?.name}</b>
                        </span>
                        <span>
                          <FormattedMessage id="pdi.form.order-detail.select-item.size" />
                          : <b>{item?.sizeLabel}</b>
                        </span>
                        <span>
                          <FormattedMessage id="pdi.form.order-detail.select-item.model-id" />
                          : <b> {item?.modelId}</b>
                        </span>
                      </div>
                    </span>
                    <button className="vtmn-btn vtmn-btn_size--small vtmn-btn_variant--primary">
                      <FormattedMessage id="pdi.form.order-detail.select-item.select-item" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
export default OrderNumberInput;
