import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
const userStoreSlice = createSlice({
  name: "userStore",
  initialState: {
    userStoreInfo: null,
    userCountryInfo: null,
    activeUserDetails: null,
    userLocale: "en-GB",
    activeAppType: null,
  },
  reducers: {
    selectUserStore: (state, action) => {
      state.userStoreInfo = action?.payload;
    },
    setActiveUserDetails: (state, action) => {
      state.activeUserDetails = action?.payload;
    },
    setUserLocale: (state, action) => {
      state.userLocale = action?.payload;
    },
    setActiveAppType: (state, action) => {
      state.activeAppType = action?.payload;
    },
  },
});

const persistConfig = {
  key: "userStore",
  storage,
  version: 1,
};
export const userStoreReducer = persistReducer(
  persistConfig,
  userStoreSlice.reducer
);
export const {
  selectUserStore,
  setActiveUserDetails,
  setUserLocale,
  setActiveAppType,
} = userStoreSlice.actions;
