import Layout from "../../Common/layouts";
const WorkShopApp = () => {
  return (
    <Layout>
      <img
        src={`${process.env.PUBLIC_URL}/coming-soon.gif`}
        alt="coming-soon"
        className="vtmn-m-auto"
      ></img>
    </Layout>
  );
};

export default WorkShopApp;
