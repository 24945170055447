import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Layout from "../Common/layouts";
import NavigationBlocks from "../Common/components/NavigationBlocks";
import { useDispatch } from "react-redux";
import { VpIcon } from "@vtmn-play/react";
import { resetPDIQuestionnaire } from "./PDI/redux/slices/PDISlice";
import { resetRentalQuestionnaire } from "./Rental/redux/slices/RentalSlice";
import { useAPI } from "../Common/utils/APIUtils";
import { AppTypeIDMappers, NavigationBlocksMapper } from "../Common/Constants";
import { handleError, handleLoading } from "../Common/utils/helpers";
const Homepage = () => {
  const dispatch = useDispatch();

  const { data, error, isLoading } = useAPI(
    `/api/v1/inspection_types?sortBy[]=id&descending[]=false`
  );

  // To clear all the pre populated forms in both rental and PDI
  useEffect(() => {
    dispatch(resetPDIQuestionnaire());
    dispatch(resetRentalQuestionnaire());
  }, [dispatch]);

  if (!data && isLoading) return <Layout>{handleLoading()}</Layout>;
  if (!data && error) return <Layout>{handleError()}</Layout>;

  if (data?.items?.length) {
    const AppTypeID = data?.items?.map(
      (item) => AppTypeIDMappers[item?.["id"]]
    );
    const getNavigationBlocksData = AppTypeID.map(
      (appType) => NavigationBlocksMapper[appType]?.meta
    );
    const navigationBlocks = getNavigationBlocksData
      ?.filter((value) => value !== undefined && value !== null)
      .map((item) => ({
        title: <FormattedMessage id={item?.phraseKey} />,
        url: item?.redirectUrl,
        icon: <VpIcon name={item?.vpIcon} size={28} />,
      }));

    return (
      <Layout>
        <h1 className="vtmn-px-4  vtmn-text-xl vtmn-font-bold vtmn-text-center vtmn-text-grey-dark-1 ">
          <FormattedMessage id="quality-control.app-hero-title" />
        </h1>
        <h6 className="vtmn-px-4 vtmn-text-md vtmn-text-center vtmn-text-grey-dark-3 vtmn-mx-auto">
          <FormattedMessage id="quality-control.app-sub-title" />
        </h6>
        <NavigationBlocks blocks={navigationBlocks} />
        <p className="vtmn-text-xs vtmn-text-center vtmn-text-grey-dark-3">
          <FormattedMessage id="quality-control.app-select-service" />
        </p>
      </Layout>
    );
  }
};
export default Homepage;
