import { useDispatch, useSelector } from "react-redux";
import Questionnaire from "../../../../Common/components/Questionnaire";
import { useAPI } from "../../../../Common/utils/APIUtils";
import { addResponse, removePDIResponse } from "../../redux/slices/PDISlice";

const PDIInspectionQuestionnaireLayout = ({ navigateToNextStep }) => {
  const {
    pdi: {
      inspectionInfo: { purchaseChannel, productCategory },
      responses: questionnaireResponse,
    },
    userStore: { activeAppType },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const { data, error, isLoading } = useAPI(
    `/api/v1/questions?purchase_channel=${purchaseChannel}&inspection_type=${activeAppType}&category=${productCategory}`
  );

  const handleInputChange = (item, userResponse) => {
    const { created_at, group, updated_at, ...rest } = item;
    if (
      (item?.acceptable_response_options?.text ||
        item?.acceptable_response_options?.calendar) &&
      !userResponse?.notes?.trim()?.length
    ) {
      dispatch(removePDIResponse(item?.store_product_question_id));
      return;
    }
    dispatch(addResponse({ ...rest, userResponse }));
  };
  return (
    <Questionnaire
      navigateToNextStep={navigateToNextStep}
      handleInputChange={handleInputChange}
      questionnaireResponse={questionnaireResponse}
      data={data}
      error={error}
      isLoading={isLoading}
      activeAppType={activeAppType}
    />
  );
};
export default PDIInspectionQuestionnaireLayout;
