import { FormattedMessage, useIntl } from "react-intl";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { useDispatch } from "react-redux";
import { setItemInfo } from "../../redux/slices/PDISlice";
const Scanner = ({ setScannerConfig, scannerConfig }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleScanDecode = (data) => {
    if (scannerConfig?.scanReference === "orderNumber") {
      let orderIDComponents = data?.trim()?.split(":");
      dispatch(
        setItemInfo({
          orderReferenceNumber: orderIDComponents[orderIDComponents.length - 1],
        })
      );
    } else if (scannerConfig?.scanReference === "serialNumber") {
      dispatch(
        setItemInfo({
          serialNumber: data
            ?.toString()
            ?.replace(/[^a-zA-Z0-9]/g, "")
            ?.trim(),
        })
      );
    }

    setScannerConfig({
      isOpen: false,
      scanReference: undefined,
    });
  };

  return (
    <div className="scanner-overlay">
      <span
        className="vtmx-close-circle-line vtmn-z-30 vtmn-w-min vtmn-text-4xl close-btn vtmn-text-white "
        onClick={() => {
          setScannerConfig({
            isOpen: false,
            scanReference: undefined,
          });
        }}
      ></span>
      <QrScanner
        scanDelay={300}
        onDecode={(data) => {
          handleScanDecode(data);
        }}
        onError={(error) => {
          setScannerConfig({
            isOpen: false,
            scanReference: undefined,
          });
          alert(intl.formatMessage({ id: "pdi.inspection.scan.error" }));
        }}
        containerStyle={{
          background: "black",
          position: "absolute",
          height: "100%",
          width: "100%",
          paddingTop: "0%",
        }}
        stopDecoding={!scannerConfig?.isOpen}
      />
      <div className="vtmn-font-bold vtmn-my-3 vtmn-relative vtmn-text-center vtmn-text-lg vtmn-text-white vtmn-w-3/4 vtmn-m-auto">
        <FormattedMessage id="pdi.inspection.scan.warning"></FormattedMessage>
      </div>
    </div>
  );
};
export default Scanner;
