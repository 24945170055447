import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { VpIcon } from "@vtmn-play/react";
const Footer = () => {
  const intl = useIntl();
  return (
    <footer className="vtmn-p-2 vtmn-flex vtmn-flex-rows">
      <a
        className=""
        href={intl.formatMessage({ id: "pdi.home.feedback-link" })}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="vp-link vtmn-flex vtmn-items-center ">
          <FormattedMessage id="pdi.home.feedback.title" />
          <VpIcon name="external-link" size={28} />
        </div>
      </a>
      <span className="vtmn-ml-auto">
        <FormattedMessage id="pdi.app-version" />
      </span>
    </footer>
  );
};

export default Footer;
