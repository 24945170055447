import React from "react";
import { Link } from "react-router-dom";
const StockAdjustment = () => {
  return (
    <ol
      style={{
        fontSize: "large",
        listStyle: "inside",
        margin: "2px 38px",
        lineHeight: "35px",
      }}
    >
      <li style={{ color: "#3643ba" }}>
        <Link
          to="https://docs.google.com/forms/d/e/1FAIpQLSci93573HmAtBRIZY3z1v9RQ8-nrIpCRqvzNre2K9HQzHw72Q/viewform"
          target="_blank"
        >
          To request a change of product status
        </Link>
      </li>
    </ol>
  );
};
export default StockAdjustment;
