import React, { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import getTranslations from "../../APIs/getTranslations";
import { Outlet } from "react-router";
import { useSelector } from "react-redux";

const TranslationWrapper = () => {
  const [translations, setTranslations] = useState(null);
  const { userLocale } = useSelector((state) => state.userStore);
  useEffect(() => {
    const initializeTranslations = async () => {
      try {
        const loadedTranslations = await getTranslations(userLocale);
        setTranslations(loadedTranslations);
      } catch (error) {
        // Handle error as needed
        console.log("Error loading translations");
      }
    };

    initializeTranslations();
  }, [userLocale]);

  return translations ? (
    <IntlProvider locale={userLocale} messages={translations}>
      <Outlet />
    </IntlProvider>
  ) : null;
};

export default TranslationWrapper;
