import React, { useEffect } from "react";

import { Outlet } from "react-router-dom";
import { getCookie } from "../utils/cookie";
import { clearSessionAndRedirectToLogin } from "../utils/session";
import { fetchDataWithHeaders, useAPI } from "../utils/APIUtils";
import logout from "../APIs/logout";
import { useDispatch } from "react-redux";
import { setActiveUserDetails } from "../../Redux/CommonSlices/userStoreSlice";

export const ProtectedLayout = () => {
  // GET USER INFO FROM BACKEND AND SET IT TO LOCAL STORAGE
  const dispatch = useDispatch();
  const { data } = useAPI("/api/v1/users/me");

  useEffect(() => {
    // dispatch in useEffect to avoid multiple rerender
    if (data) {
      dispatch(setActiveUserDetails(data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    const userInfo = getCookie("userInfo");
    if (!userInfo) clearSessionAndRedirectToLogin();
    const checkTokenValidity = async () => {
      try {
        const response = await fetchDataWithHeaders(
          `/api/v1/users/introspect_token`
        );
        if (
          response?.status !== 200 ||
          !response?.body?.is_email_valid ||
          !response?.body?.is_token_valid
        ) {
          logout(userInfo?.pisri);
        }
      } catch (error) {
        console.error("Error checking user token:", error);
        logout(userInfo?.pisri);
      }
    };

    // Event listener for window focus
    const handleFocus = () => {
      checkTokenValidity();
    };

    // Add event listener for window focus
    window.addEventListener("focus", handleFocus);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, []);
  return <Outlet />;
};
