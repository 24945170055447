import React, { useCallback, useEffect, useState } from "react";
import FAQ from "../../Common/components/FAQ";
import { FormattedMessage, useIntl } from "react-intl";
import NavigationBlocks from "../../Common/components/NavigationBlocks";
import { VpIcon } from "@vtmn-play/react";
import { useDispatch, useSelector } from "react-redux";
import createOrUpdateUser from "../../Common/APIs/createOrUpdateUser";
import { getCookie } from "../../Common/utils/cookie";
import YouTube from "react-youtube";
import "./index.css";
import { resetPDIQuestionnaire } from "./redux/slices/PDISlice";
import { resetRentalQuestionnaire } from "../Rental/redux/slices/RentalSlice";

const PDIHomepage = () => {
  const dispatch = useDispatch();

  const intl = useIntl();
  const [videoWidth, setVideoWidth] = useState(window.innerWidth * 0.8); // Set initial width relative to screen width
  const [videoHeight, setVideoHeight] = useState(window.innerHeight * 0.8); // Set initial height relative to screen heigh
  const { activeUserDetails } = useSelector((state) => state.userStore);

  const handleResize = useCallback(() => {
    setVideoWidth(window.innerWidth * 0.8); // Adjust based on your preference
    setVideoHeight(window.innerHeight * 0.8); // Adjust based on your preference
  }, []);

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  // Delete any questionnaire if present
  useEffect(() => {
    dispatch(resetPDIQuestionnaire());
    dispatch(resetRentalQuestionnaire());
  }, [dispatch]);

  const showTutorial = () => {
    const opts = {
      height: videoHeight,
      width: videoWidth,
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        controls: 1,
        playsinline: 1,
      },
    };
    return (
      <div className="video-overlay">
        <div className="video-popup">
          <span
            className="vtmx-close-circle-line vtmn-z-30 vtmn-w-min vtmn-text-4xl close-btn vtmn-text-white"
            onClick={async () => {
              const resp = await createOrUpdateUser({
                is_new_user: false,
                access_token: getCookie("fedInfo")?.access_token,
                uid: getCookie("userInfo")?.uid,
              });
              if (resp?.status === 200) {
                window.location.reload();
              } else {
                alert(
                  "Failed to update user info. Please reload the page to continue"
                );
              }
            }}
          ></span>
          <YouTube
            videoId="WKDePOafTB0"
            id="tutorial-video"
            title="Tutorial"
            opts={opts}
            style={{}}
            onError={(event) => {
              console.error("YouTube Player Error:", event);
            }}
            onReady={(event) => {
              console.log("YouTube Player Ready:", event);
            }}
          />
        </div>
      </div>
    );
  };
  const navigationBlocks = [
    {
      title: <FormattedMessage id="pdi.home.links.start-inspection" />,
      url: "inspection",
      icon: <VpIcon name="power" size={28} />,
    },
    {
      title: <FormattedMessage id="pdi.home.links.resources-title" />,
      url: "resources",
      icon: <VpIcon name="file" size={28} />,
    },
    {
      title: <FormattedMessage id="pdi.home.links.training" />,
      url: "training",
      icon: <VpIcon name="award" size={28} />,
    },
    {
      title: <FormattedMessage id="pdi.home.links.reporting" />,
      url: "reports",
      icon: <VpIcon name="bar-chart" size={28} />,
    },
  ];
  const FAQItems = [
    {
      title: <FormattedMessage id="pdi.home.faq.what-is-title" />,
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "pdi.home.what-is" }),
          }}
        ></div>
      ),
    },
    {
      title: <FormattedMessage id="pdi.home.faq.why-is-important-title" />,
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "pdi.home.why-is-important" }),
          }}
        ></div>
      ),
    },
    {
      title: <FormattedMessage id="pdi.home.faq.who-for-title" />,
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "pdi.home.who-for" }),
          }}
        ></div>
      ),
    },
  ];
  return (
    <>
      {/* <button
          className="vtmn-btn vtmn-btn-sm"
          onClick={() => {
            createOrUpdateUser({
              is_new_user: true,
              access_token: getCookie("fedInfo")?.access_token,
              uid: getCookie("userInfo")?.uid,
            });
          }}
          value="Toggle video"
        >
          Toggle video
        </button> */}
      {activeUserDetails?.is_new_user && showTutorial()}
      <h1 className="vtmn-px-4  vtmn-text-xl vtmn-font-bold vtmn-text-center vtmn-text-grey-dark-1 ">
        <FormattedMessage id="pdi.app-hero-title" />
      </h1>
      {/* <h6 className="vtmn-px-4 vtmn-text-md vtmn-text-center vtmn-text-grey-dark-3 ">
          (PDI)
        </h6> */}
      <NavigationBlocks blocks={navigationBlocks} />
      <FAQ accordionItems={FAQItems} />
    </>
  );
};
export default PDIHomepage;
