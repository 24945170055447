import React, { useEffect, useState } from "react";
import { QuestionsStepsMap } from "./InspectionStepsMap";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { HandleNoAccess } from "../../utils/helpers";

const DynamicInspectionStepsRenderer = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  useEffect(() => {
    const param = new URLSearchParams(location?.search);
    const step = parseInt(param.get("step") || 0);
    // if (!param.has("step")) {
    //   param.set("step", step);
    //   navigate({ search: param.toString() }, { replace: false });
    // } else {
    setActiveStepIndex(parseInt(step));
    // }
  }, [location, navigate]);

  const updateURLStep = (step) => {
    const param = new URLSearchParams(location.search);
    param.set("step", step.toString());
    navigate({ search: param.toString() }, { replace: false });
  };

  const {
    rental: {
      inspectionInfo: {
        purchaseChannel: rentalPurchaseChannel,
        productCategory: rentalProductCategory,
      },
    },
    pdi: {
      inspectionInfo: {
        purchaseChannel: PDIPurchaseChannel,
        productCategory: PDIProductCategory,
      },
    },
    userStore: { activeAppType },
  } = useSelector((state) => state);

  const StepComponentArray =
    activeAppType === "PDI"
      ? QuestionsStepsMap?.[activeAppType]?.channels[PDIPurchaseChannel]
          ?.category[PDIProductCategory]
      : activeAppType === "RENTAL"
      ? QuestionsStepsMap?.[activeAppType]?.channels[rentalPurchaseChannel]
          ?.category[rentalProductCategory]
      : null;

  const navigateToNextStep = () => {
    if (parseInt(activeStepIndex) < StepComponentArray?.length - 1) {
      const newStep = parseInt(activeStepIndex) + 1;
      setActiveStepIndex(newStep);
      updateURLStep(newStep);
    } else {
      return <HandleNoAccess />;
    }
  };

  const navigateToFirstStep = () => {
    setActiveStepIndex(0);
    updateURLStep(0);
  };
  const navigateToPreviousStep = () => {
    if (parseInt(activeStepIndex) > 0) {
      const newStep = parseInt(activeStepIndex) - 1;
      setActiveStepIndex(newStep);
      updateURLStep(newStep);
    } else {
      return <HandleNoAccess />;
    }
  };

  const renderPage = () => {
    const CurrentComponent = StepComponentArray?.[parseInt(activeStepIndex)];
    if (!CurrentComponent) {
      return <HandleNoAccess />;
    }
    // Pass navigate functions to the current component
    return (
      <CurrentComponent
        navigateToNextStep={navigateToNextStep}
        navigateToPreviousStep={navigateToPreviousStep}
        navigateToFirstStep={navigateToFirstStep}
      />
    );
  };

  return <div>{renderPage()}</div>;
};

export default DynamicInspectionStepsRenderer;
