export default async function getTranslations(locale) {
  let myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    `Basic ${process.env.REACT_APP_PHRASE_KEY}`,
  );
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const translations = await fetch(
    `${process.env.REACT_APP_PHRASE_ENDPOINT}${process.env.REACT_APP_PHARSE_PROJECT_ID}/locales/${locale}/download?file_format=json`,
    requestOptions,
  )
    .then((response) => response.text())
    .then((result) => {
      const response = JSON.parse(result);
      const messages = {};
      for (const key in response) {
        if (response?.hasOwnProperty(key)) {
          const messageKey = `${key}`;
          messages[messageKey] = `${response[key].message}`;
        }
      }
      return messages;
    })
    .catch((err) => console.log(err));
  return translations;
}
