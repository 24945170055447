import { Route, Routes } from "react-router-dom";
import NotFound from "../../../../Common/components/NotFound";
import DynamicInspectionStepsRenderer from "../../../../Common/components/DynamicInspectionStepsRenderer";
import { ValidRouteGuard } from "../../../../Common/authentication/ValidRouteGuard";
import PDIInspectionHomepage from ".";
import InspectionInfo from "../../components/InspectionInfo";

const Inspection = (props) => {
  return (
    <Routes>
      <Route index element={<PDIInspectionHomepage />} />
      <Route
        element={
          <ValidRouteGuard
            currentRouteName="purchaseChannel"
            validRoutes={["physical", "online"]}
          />
        }
      >
        <Route path=":purchaseChannel" element={<InspectionInfo />} />
        <Route
          path=":purchaseChannel/questions"
          element={<DynamicInspectionStepsRenderer />}
        />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default Inspection;
