import { fetchDataWithHeaders } from "../utils/APIUtils";

export default async function createOrUpdateUser(userInfo) {
  const payload = {};
  if (userInfo.is_new_user !== undefined)
    payload["is_new_user"] = userInfo?.is_new_user;
  else {
    payload["name"] = userInfo?.displayName;
    payload["email"] = userInfo?.mail;
  }
  return await fetchDataWithHeaders(
    `/api/v1/users/${userInfo.uid}`,
    { "Content-Type": "application/json", "Cache-Control": "no-cache" },
    { method: "POST" },
    {},
    payload
  );
}
