import React from "react";
import { Link } from "react-router-dom";
const Training = () => {
  return (
    <ol
      style={{
        fontSize: "large",
        listStyle: "inside",
        margin: "2px 38px",
        lineHeight: "35px",
      }}
    >
      <li style={{ color: "#3643ba" }}>
        <Link
          to="https://docs.google.com/document/d/1owIyDJ0iewTrpSHyoaFRK_Jm8vjTLoylERUFdAXToCo/edit"
          target="_blank"
        >
          In store Rental
        </Link>
      </li>
    </ol>
  );
};
export default Training;
