import { AppTypeIDMappers, PurchaseChannelsIDMappers } from "../Constants";
import { fetchDataWithHeaders } from "../utils/APIUtils";
import { getKeyByValue } from "../utils/helpers";

export default async function submitQuestionnaire(userStoreInfo, activAppInfo) {
  const appTypeID = getKeyByValue(
    AppTypeIDMappers,
    userStoreInfo?.activeAppType
  );
  const purchaseChannelID = getKeyByValue(
    PurchaseChannelsIDMappers,
    activAppInfo?.inspectionInfo?.purchaseChannel
  );
  const requestObject = {
    order_number: activAppInfo?.itemInfo?.orderNumber?.trim(),
    loyalty_card: activAppInfo?.itemInfo?.loyaltyCard?.trim(),
    member_id: userStoreInfo?.activeUserDetails?.fedid,
    product_sku: activAppInfo?.itemInfo?.modelId?.trim(),
    store_number: userStoreInfo?.userStoreInfo?.store_number,
    inspector: userStoreInfo?.activeUserDetails?.id,
    checklist: activAppInfo?.responses,
    serial_number: activAppInfo?.itemInfo?.serialNumber?.trim(),
    model_id: activAppInfo?.itemInfo?.modelId?.trim(),
    model_name: activAppInfo?.itemInfo?.modelName?.trim(),
    rental_id: activAppInfo?.itemInfo?.articleID?.trim(),
    start_time: activAppInfo?.questionnaireStartTime,
    end_time: Date.now(),
    inspection_type_id: appTypeID,
    purchase_channel_id: purchaseChannelID,
    assets: activAppInfo?.assets?.map((item) => ({
      attachment: item.attachment,
    })),
  };
  return await fetchDataWithHeaders(
    `/api/v2/inspections`, // URL
    { "Content-Type": "application/json", "Cache-Control": "no-cache" }, // Header
    { method: "POST" }, // Method
    {}, // Options
    requestObject // Body
  );
}
