import { VpButton } from "@vtmn-play/react";
import React from "react";

import { FormattedMessage } from "react-intl";
import ProgressBar from "../../../../Common/components/ProgressBar/index.jsx";
import {
  VtmnList,
  VtmnListItem,
  VtmnListItemEndAction,
  VtmnListItemText,
} from "@vtmn/react";
import { useAPI } from "../../../../Common/utils/APIUtils.js";
import { useSelector } from "react-redux";
import {
  handleError,
  handleLoading,
} from "../../../../Common/utils/helpers.js";
import { renderCircularResponses } from "../../../../Common/components/Questionnaire/questionnaireUtils.js";
import { loadError } from "../../../../Static/images/icons.js";

const RentalCircularInspection = ({ navigateToNextStep }) => {
  const {
    itemInfo: { articleID },
    inspectionInfo: { productCategory },
  } = useSelector((state) => state.rental);
  const { data, error, isLoading } = useAPI(
    `/api/v2/inspections/search?rental_id=${articleID}`
  );
  if (!data && isLoading) return handleLoading();
  if (!data && error) return handleError();
  if (data) {
    return (
      <div className="vtmn-w-full vtmn-px-3 vtmn-my-3">
        <h1 className="vtmn-font-bold vtmn-text-2xl">
          <FormattedMessage id="rental.form.inspection.circular.title" />
        </h1>
        <ProgressBar completion={"33"} />
        <section className="vtmn-my-3">
          <VtmnList divider>
            <VtmnListItem divider={false}>
              <VtmnListItemText>
                <div className="vtmn-flex vtmn-items-center">
                  <span className="vtmn-w-1/3">
                    <FormattedMessage id="rental.cnc.hub_inspection.article_id" />
                  </span>
                  <span className="vtmn-w-2/3 vtmn-font-extrabold">
                    {data?.rental_id}
                  </span>
                </div>
              </VtmnListItemText>
            </VtmnListItem>
            <VtmnListItem divider={false}>
              <VtmnListItemText>
                <div className="vtmn-flex vtmn-items-center">
                  <span className="vtmn-w-1/3">
                    <FormattedMessage id="rental.cnc.hub_inspection.store_id" />
                  </span>
                  <span className="vtmn-w-2/3 vtmn-font-extrabold">
                    {data?.store_number}
                  </span>
                </div>
              </VtmnListItemText>
            </VtmnListItem>
            <VtmnListItem divider={false}>
              <VtmnListItemText>
                <div className="vtmn-flex vtmn-items-center">
                  <span className="vtmn-w-1/3">
                    <FormattedMessage id="rental.cnc.hub_inspection.inspected_by" />
                  </span>
                  <span className="vtmn-w-2/3 vtmn-font-extrabold">
                    {data?.member_id}
                  </span>
                </div>
              </VtmnListItemText>
            </VtmnListItem>
            <VtmnListItem divider={false}>
              <VtmnListItemText>
                <div className="vtmn-flex vtmn-items-center">
                  <span className="vtmn-w-1/3">
                    <FormattedMessage id="rental.cnc.hub_inspection.category" />
                  </span>
                  <span className="vtmn-w-2/3 vtmn-font-extrabold">
                    {productCategory}
                  </span>
                </div>
              </VtmnListItemText>
            </VtmnListItem>
          </VtmnList>
        </section>
        <section>
          <VtmnList className="vtmn-my-3">
            <VtmnListItem>
              <VtmnListItemText className="questionniare-question"></VtmnListItemText>
              <VtmnListItemEndAction className="questionniare-options ">
                <span className="vtmn-mx-1">
                  <FormattedMessage id="rental.form.inspection.check-complete" />
                </span>
                <span className="vtmn-mx-1">
                  <FormattedMessage id="rental.form.inspection.check-na" />
                </span>
              </VtmnListItemEndAction>
            </VtmnListItem>
            {data?.questionnaires?.map((item, _indx) =>
              renderCircularResponses(item)
            )}
          </VtmnList>
        </section>
        <div className="vtmn-py-2">
          <VpButton
            className="vp-button vtmn-btn_size--stretched"
            variant="primary"
            onClick={() => navigateToNextStep()}
          >
            <FormattedMessage id="pdi.nav.continue" />
          </VpButton>
        </div>
      </div>
    );
  } else {
    return (
      <div className="vtmn-w-full vtmn-text-center vtmn-px-3 vtmn-my-3">
        {loadError}
        <h6 className="vtmn-p-5 vtmn-text-md vtmn-text-center vtmn-text-grey-dark-3 ">
          <FormattedMessage id="rental.form.inspection.circular.inspection-not-found" />
        </h6>
        <VpButton
          className="vp-button vtmn-btn_size--stretched"
          variant="primary"
          onClick={() => navigateToNextStep()}
        >
          <FormattedMessage id="pdi.nav.continue" />
        </VpButton>
      </div>
    );
  }
};

export default RentalCircularInspection;
