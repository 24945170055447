import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import NotFound from "./Common/components/NotFound/index.jsx";

import TranslationWrapper from "./Common/components/TranslationWrapper";
import ErrorBoundary from "./Common/components/ErrorBoundry";
import Login from "./Common/authentication/Login";
import Authorise from "./Common/authentication/Authorise";
import { ProtectedLayout } from "./Common/authentication/ProtectedLayout";
import { AuthLayout } from "./Common/authentication/AuthLayout";
import PDIApp from "./Apps/PDI/pdi-routes.jsx";
import RentalApp from "./Apps/Rental/rental-routes.jsx";
import Homepage from "./Apps/index.js";
import SecondLifeApp from "./Apps/SecondLife/index.jsx";
import WorkShopApp from "./Apps/WorkShop/index.jsx";
import StoreSelector from "./Common/components/StoreSelector/index.jsx";
import Layout from "./Common/layouts/index.jsx";

export const App = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<TranslationWrapper />}>
      <Route element={<ProtectedLayout />}>
        <Route
          index
          element={<StoreSelector />}
          errorElement={
            <Layout>
              <ErrorBoundary />
            </Layout>
          }
        />
        <Route
          path="home"
          element={<Homepage />}
          errorElement={
            <Layout>
              <ErrorBoundary />
            </Layout>
          }
        />
        <Route
          path="pdi/*"
          element={<PDIApp />}
          errorElement={
            <Layout>
              <ErrorBoundary />
            </Layout>
          }
        />
        <Route
          path="rental/*"
          element={<RentalApp />}
          errorElement={
            <Layout>
              <ErrorBoundary />
            </Layout>
          }
        />
        <Route
          path="secondlife/*"
          element={<SecondLifeApp />}
          errorElement={
            <Layout>
              <ErrorBoundary />
            </Layout>
          }
        />
        <Route
          path="workshop/*"
          element={<WorkShopApp />}
          errorElement={
            <Layout>
              <ErrorBoundary />
            </Layout>
          }
        />

        <Route
          path="*"
          element={
            <Layout>
              <NotFound />
            </Layout>
          }
        />
      </Route>
      <Route element={<AuthLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/authorise" element={<Authorise />} />
      </Route>
    </Route>
  )
);
