import React from "react";
import { Link } from "react-router-dom";
const Resources = () => {
  return (
    <ol
      style={{
        fontSize: "large",
        listStyle: "inside",
        margin: "2px 38px",
        lineHeight: "35px",
      }}
    >
      <li style={{ color: "#3643ba" }}>
        <Link
          to="https://drive.google.com/file/d/1zj8-Mwc8HJFAkA7t1RoqVfuS8r7UlPAb/view"
          target="_blank"
        >
          Bike Catalogue
        </Link>
      </li>
      <li style={{ color: "#3643ba" }}>
        <Link
          to="https://drive.google.com/file/d/1L7afTTNWhI48BSTXIO2l7nFIKjyEMO4Y/view"
          target="_blank"
        >
          Big Blue Book
        </Link>
      </li>
      <li style={{ color: "#3643ba" }}>
        <Link
          to="https://docs.google.com/presentation/d/1vR7PVrgA5WW0qJ4DQBLRyW_DTTPJ3SzZzjuukaKTXkk/edit#slide=id.p4"
          target="_blank"
        >
          PDI Training Resources
        </Link>
      </li>
      <li style={{ color: "#3643ba" }}>
        <Link
          to="https://docs.google.com/spreadsheets/d/1gHEMaGR5m7V6QyOoF-u-_nRP3Yzo0bJ6tZqaFVybCw8/edit#gid=1213994666"
          target="_blank"
        >
          PDI Tool Audit
        </Link>
      </li>
      <li style={{ color: "#3643ba" }}>
        <Link
          to="https://youtu.be/WKDePOafTB0?si=jS4Xay_fFYhMcyNR"
          target="_blank"
        >
          YouTube video
        </Link>
      </li>
      <li style={{ color: "#3643ba" }}>
        <Link
          to="https://docs.google.com/forms/d/e/1FAIpQLSeOgzx67_pNXq-FSJowu15I9EwRHwy__nsixxklR_t9DgslGw/viewform"
          target="_blank"
        >
          PDI Training Validation Form
        </Link>
      </li>
      <li style={{ color: "#3643ba" }}>
        <Link
          to="https://docs.google.com/presentation/d/1u--dYwQhThM9E1o3aIuPgC0_hpTiw6hLbzX3NX_NItc/preview?slide=id.g2c3dfbe32fd_0_4830"
          target="_blank"
        >
          Quality Control Organigram
        </Link>
      </li>
    </ol>
  );
};
export default Resources;
