import React from "react";
import { Outlet, useParams } from "react-router-dom";
import NotFound from "../components/NotFound";

export const ValidRouteGuard = ({ currentRouteName, validRoutes = [] }) => {
  const params = useParams();
  if (!validRoutes.includes(params[currentRouteName])) {
    return <NotFound />;
  }
  return <Outlet />;
};
