import { combineReducers } from "@reduxjs/toolkit";
import { userStoreReducer } from "../CommonSlices/userStoreSlice";
import { pdiReducer } from "../../Apps/PDI/redux/slices/PDISlice";
import { rentalReducer } from "../../Apps/Rental/redux/slices/RentalSlice";

const rootReducer = combineReducers({
  pdi: pdiReducer,
  rental: rentalReducer,
  userStore: userStoreReducer,
});
export default rootReducer;
