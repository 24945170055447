import {
  VpButton,
  VpInput,
  VpIcon,
  VpFormControl,
  VpCheckbox,
  VpIconButton,
} from "@vtmn-play/react";
import React, { useEffect, useState } from "react";
import "./index.css";
import { useSelector, useDispatch } from "react-redux";

import { FormattedMessage } from "react-intl";
import {
  resetSavedResponses,
  setInspectionInfo,
  setItemInfo,
} from "../../redux/slices/PDISlice";
import { useNavigate } from "react-router-dom";
import { isAlphanumeric } from "../../../../Common/utils/helpers";

import Scanner from "./Scanner";
import LoyaltyCardInput from "./LoyaltyCardInput";
import OrderNumberInput from "./OrderNumberInput";
import SerialNumberInput from "./SerialNumberInput";
import ModelNumberInput from "./ModelNumberInput";
const PDIInspectionInfo = (props) => {
  const [scannerConfig, setScannerConfig] = useState({
    isOpen: false,
    scanReference: undefined,
  });
  const [loading, setLoading] = useState({
    orderID: false,
    serialNumber: false,
    modelId: false,
  });
  const [mounted, setMounted] = useState(false); // Flag to track component mount status
  const [failedAPIs, setFailedAPIs] = useState({
    orderAPI: false,
    serialNumberAPI: false,
    modelIdAPI: false,
  });
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const {
    itemInfo: { modelId, modelName, serialNumber },
    inspectionInfo: { purchaseChannel, productCategory },
  } = useSelector((state) => state.pdi);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let processedValue;

    // Trim/validate data based on condition.
    switch (name) {
      case "modelId":
        processedValue = value?.replace(/[^0-9]/g, "");
        break;
      case "serialNumber":
        setFailedAPIs((prevState) => ({
          ...prevState,
          serialNumberAPI: false,
        }));
        // Remove space between copy pasted values
        processedValue = value?.replace(/[^a-zA-Z0-9]/g, "")?.trim();
        break;
      default:
        processedValue = value;
        break;
    }
    dispatch(
      setItemInfo({
        [name]: processedValue,
      })
    );
  };

  const populateFields = (filtered_items) => {
    const isEbike =
      /(electric(al|als)?|e[- ]?bike)/i.test(filtered_items?.title) && // Matches "electric", "electrical", "electricals", "ebike", "e-bike" (case-insensitive)
      filtered_items?.technicalInfos?.some((item) =>
        /battery/i.test(item?.name)
      );

    dispatch(
      setInspectionInfo({
        productCategory: isEbike ? "e-bike" : "bike",
      })
    );
    dispatch(resetSavedResponses());
    dispatch(
      setItemInfo({
        modelName: filtered_items?.title,
        modelId: filtered_items?.modelId,
      })
    );
  };
  return (
    <div className="vtmn-w-full vtmn-px-3 vtmn-my-3">
      <h1 className="vtmn-font-bold vtmn-text-2xl">
        {purchaseChannel === "ONLINE_ORDER" ? (
          <FormattedMessage id="pdi.form.product-detail.scan.title" />
        ) : (
          <FormattedMessage id="pdi.form.product-detail.title" />
        )}
      </h1>
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {purchaseChannel === "ONLINE_ORDER" && (
          <OrderNumberInput
            loading={loading}
            setScannerConfig={setScannerConfig}
            populateFields={populateFields}
            setLoading={setLoading}
            setFailedAPIs={setFailedAPIs}
            mounted={mounted}
            setMounted={setMounted}
          />
        )}

        <ModelNumberInput
          handleInputChange={handleInputChange}
          mounted={mounted}
          setMounted={setMounted}
          setLoading={setLoading}
          failedAPIs={failedAPIs}
          setFailedAPIs={setFailedAPIs}
          loading={loading}
        />
        <div className="vtmn-relative ">
          <VpFormControl className="vtmn-my-1">
            <VpCheckbox
              id="ebike-checkbox"
              checked={productCategory === "e-bike"}
              onChange={(event) => {
                const { checked } = event.target;
                dispatch(
                  setInspectionInfo({
                    productCategory: checked ? "e-bike" : "bike",
                  })
                );
                dispatch(resetSavedResponses());
              }}
            >
              <FormattedMessage id="pdi.form.inspection.model.is_ebike" />
            </VpCheckbox>
          </VpFormControl>
        </div>

        <div className="vtmn-relative vtmn-mt-5">
          <VpFormControl required>
            <VpFormControl.Label for="model-name">
              <FormattedMessage id="pdi.form.product-detail.model-name" />
            </VpFormControl.Label>
            <VpInput
              className={`vtmn-w-full`}
              onChange={handleInputChange}
              placeholder="eg: Van Rysel RCR Pro"
              value={modelName}
              name="modelName"
              disabled={loading?.orderID}
            />
          </VpFormControl>
        </div>

        <SerialNumberInput
          handleInputChange={handleInputChange}
          loading={loading}
          setScannerConfig={setScannerConfig}
          failedAPIs={failedAPIs}
          mounted={mounted}
          setMounted={setMounted}
          setLoading={setLoading}
          setFailedAPIs={setFailedAPIs}
        />
        {purchaseChannel === "PHYSICAL_INSPECTION" && (
          <LoyaltyCardInput handleInputChange={handleInputChange} />
        )}
        <div className="vtmn-py-2 vtmn-mt-5">
          <VpButton
            className="vp-button vtmn-btn_size--stretched"
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              navigate("questions", { replace: false });
            }}
            disabled={
              !modelName?.trim() ||
              !modelId?.trim() ||
              !serialNumber?.trim() ||
              isAlphanumeric(serialNumber) ||
              loading?.orderID ||
              loading?.serialNumber
            }
          >
            <FormattedMessage id="pdi.nav.submit" />
          </VpButton>
        </div>
      </form>
      {(purchaseChannel === "ONLINE_ORDER" ||
        purchaseChannel === "PHYSICAL_INSPECTION") &&
        scannerConfig?.isOpen && (
          <Scanner
            setScannerConfig={setScannerConfig}
            scannerConfig={scannerConfig}
          />
        )}
    </div>
  );
};

export default PDIInspectionInfo;
