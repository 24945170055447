import React from "react";
import "./index.css";
import { VpButton, VpLinkList } from "@vtmn-play/react";
import { VpCheckCircleIcon } from "@vtmn-play/icons/react";
import { FormattedMessage } from "react-intl";

const RentalBikeInspectionPrelist = ({ navigateToNextStep }) => {
  const inspectionPreList = [
    {
      title: "Prepare your toolkit",
    },
    {
      title: "Prepare the work enviornment",
    },
    {
      title: "Remember the M check",
    },
    {
      title: "Torque check",
    },
  ];

  return (
    <div className="vtmn-w-full vtmn-px-3 vtmn-my-3">
      <h1 className="vtmn-font-bold vtmn-text-2xl">
        <FormattedMessage id="pdi.form.inspection.title" />
      </h1>
      <div className="vtmn-font-bold vtmn-my-3">
        <FormattedMessage id="pdi.form.inspection.intro.subtitle" />
      </div>
      <div>
        <VpLinkList
          size="small"
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          {inspectionPreList.map((item, index) => (
            <VpLinkList.Item key={index + 1} iconSlot={<></>}>
              <VpCheckCircleIcon className="vtmn-mr-2" />
              {item.title}
            </VpLinkList.Item>
          ))}
          <div className="vtmn-mx-2 vtmn-my-4">
            <img
              src={`${process.env.PUBLIC_URL}/wrench.png`}
              alt="wrench"
              className="vtmn-m-auto"
            ></img>
            <img
              src={`${process.env.PUBLIC_URL}/nonNegotiable.png`}
              alt="nonNegotiable"
              className="vtmn-m-auto vtmn-w-3/4"
            ></img>
          </div>
        </VpLinkList>

        <div className="vtmn-py-2">
          <VpButton
            className="vp-button vtmn-btn_size--stretched"
            variant="primary"
            onClick={() => navigateToNextStep()}
          >
            <FormattedMessage id="pdi.nav.start" />
          </VpButton>
        </div>
      </div>
    </div>
  );
};

export default RentalBikeInspectionPrelist;
