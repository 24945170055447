import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getCookie } from "../utils/cookie";

export const AuthLayout = () => {
  const fedInfo = getCookie("fedInfo");
  if (fedInfo) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};
