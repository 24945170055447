import {
  VtmnListItem,
  VtmnListItemEndAction,
  VtmnListItemText,
} from "@vtmn/react";
import { FormattedMessage } from "react-intl";
import { handleQuestionFormatError } from "../../utils/helpers";

export const allQuestionsAnswered = (
  currentGroupQuestions,
  questionnaireResponse,
  totalGroups,
  groupNumber,
  allQuestions
) => {
  // NOTE - ONLY QUESTIONS OF TYPE RADIO ARE MANDATORY.
  //  Get all question IDs of current active group (group displayed) and filter only RADIO type questions
  const currentGroupRadioQuestionIDs = currentGroupQuestions
    .filter((item) => item?.acceptable_response_options?.radio)
    ?.map((item) => item.id);

  // Get all questions of the entire questionnaire which are of type RADIO.
  const allQuestionsOfTypeRadio = allQuestions?.filter(
    (item) => item?.acceptable_response_options?.radio
  );

  // Get already answered responses stored in REDUX which are of type RADIO
  const questionnaireResponsesOfTypeRadio = questionnaireResponse?.filter(
    (item) => item?.acceptable_response_options?.radio
  );
  //Flag to determine if all the RADIO type Questions are  answered
  const isQuestionnaireIncomplete =
    questionnaireResponsesOfTypeRadio?.length < allQuestionsOfTypeRadio?.length;

  //  Check if all question IDs from current active group is present is redux state (questionnaireResponsesOfTypeRadio).If not, disable the button
  const allQuestionsAnswered = currentGroupRadioQuestionIDs.every(
    (idToCheck) => {
      // Check if the active group is the last group and also all questions of RADIO TYPE from the previous page is answered.

      if (parseInt(groupNumber) >= totalGroups && isQuestionnaireIncomplete) {
        return false;
      }
      return questionnaireResponsesOfTypeRadio?.some(
        (item) => item?.id === idToCheck
      );
    }
  );
  return allQuestionsAnswered;
};

// The below function groups the questions based on sequential_group_id
export const groupQuestionsByGroupNumber = (questions) => {
  return questions?.reduce((groups, currentGroupQuestions) => {
    if (!groups[currentGroupQuestions.sequential_group_id]) {
      groups[currentGroupQuestions.sequential_group_id] = [];
    }
    groups[currentGroupQuestions.sequential_group_id].push(
      currentGroupQuestions
    );
    return groups;
  }, {});
};

export const renderFinalQuestions = (
  item,
  questionnaireResponse,
  handleInputChange
) => {
  let content;
  if (item?.acceptable_response_options?.radio) {
    content = (
      <>
        <VtmnListItemText className="questionniare-question">
          <span>
            <FormattedMessage id={item.question_text} />
          </span>
        </VtmnListItemText>

        <VtmnListItemEndAction
          className="questionniare-options"
          style={{ padding: 0 }}
        >
          <label className="lbl-questionniare-checkbox">
            <input
              type="checkbox"
              className="questionniare-checkbox"
              checked={
                questionnaireResponse?.find((resp) => resp?.id === item?.id)
                  ?.response === "YES"
              }
              onChange={() => handleInputChange(item, { response: "YES" })}
            />
          </label>
          <label className="lbl-questionniare-checkbox">
            <input
              type="checkbox"
              className="questionniare-checkbox"
              checked={
                questionnaireResponse?.find((resp) => resp?.id === item?.id)
                  ?.response === "N/A"
              }
              onChange={() => handleInputChange(item, { response: "N/A" })}
            />
          </label>
        </VtmnListItemEndAction>
      </>
    );
  } else if (
    item?.acceptable_response_options?.text
    // || item?.acceptable_response_options?.attachments
  ) {
    content = (
      <>
        <VtmnListItemText className="questionniare-question">
          <span>
            <FormattedMessage id={item.question_text} />
          </span>
          <textarea
            className="vtmn-w-full vtmn-text-input"
            onChange={(e) =>
              handleInputChange(item, { notes: e?.target?.value })
            }
            value={
              questionnaireResponse?.find((resp) => resp?.id === item?.id)
                ?.notes
            }
          />
        </VtmnListItemText>
      </>
    );
  } else if (item?.acceptable_response_options?.calendar) {
    content = (
      <>
        <VtmnListItemText className="questionniare-question">
          <span>
            <FormattedMessage id={item.question_text} />
          </span>

          <input
            type="date"
            className="vtmn-w-full vtmn-text-input"
            onChange={(e) => {
              handleInputChange(item, { notes: e?.target?.value });
            }}
            value={
              questionnaireResponse?.find((resp) => resp?.id === item?.id)
                ?.notes
            }
          />
        </VtmnListItemText>
      </>
    );
  } else {
    content = handleQuestionFormatError();
  }

  return <VtmnListItem key={item.id}>{content}</VtmnListItem>;
};

export const renderCircularResponses = (item) => {
  let content;
  if (item?.checklist_selection?.length) {
    content = (
      <>
        <VtmnListItemText className="questionniare-question">
          <span>
            <FormattedMessage id={item?.question?.question_text} />
          </span>
        </VtmnListItemText>

        <VtmnListItemEndAction
          className="questionniare-options"
          style={{ padding: 0 }}
        >
          <label className="lbl-questionniare-checkbox">
            <input
              type="checkbox"
              className="questionniare-checkbox"
              checked={item?.checklist_selection === "YES"}
            />
          </label>
          <label className="lbl-questionniare-checkbox">
            <input
              type="checkbox"
              className="questionniare-checkbox"
              checked={item?.checklist_selection === "N/A"}
            />
          </label>
        </VtmnListItemEndAction>
      </>
    );
  } else if (item?.notes?.length) {
    content = (
      <>
        <VtmnListItemText className="questionniare-question">
          <span>
            <FormattedMessage id={item?.question?.question_text} />
          </span>
          <input
            className="vtmn-w-full vtmn-text-input"
            value={item?.notes}
            disabled
          />
        </VtmnListItemText>
      </>
    );
  } else {
    content = handleQuestionFormatError();
  }

  return <VtmnListItem key={item.id}>{content}</VtmnListItem>;
};
