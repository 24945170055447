import { VpInput, VpFormControl, VpCheckbox } from "@vtmn-play/react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  setAccountCreationRefused,
  setItemInfo,
} from "../../redux/slices/PDISlice";
const LoyaltyCardInput = ({ handleInputChange }) => {
  const dispatch = useDispatch();
  const {
    itemInfo: { loyaltyCard },
    accountCreationRefused,
  } = useSelector((state) => state.pdi);
  return (
    <div className="vtmn-relative vtmn-mt-5">
      <VpFormControl>
        <VpFormControl.Label for="membership-number">
          <FormattedMessage id="pdi.form.product-detail.membership-number" />
        </VpFormControl.Label>
        <VpInput
          className={`vtmn-w-full  `}
          onChange={handleInputChange}
          placeholder="eg: 2098..."
          value={loyaltyCard}
          name="loyaltyCard"
          disabled={accountCreationRefused}
        />
      </VpFormControl>
      <VpFormControl className="vtmn-my-1">
        <VpCheckbox
          id="customer-refused"
          checked={accountCreationRefused}
          onChange={(event) => {
            const isChecked = event?.target?.checked;
            dispatch(setAccountCreationRefused(isChecked));
            // If customer refused to create account, then set loyaltyCard number to Refused. Else null
            dispatch(setItemInfo({ loyaltyCard: isChecked ? "Refused" : "" }));
          }}
        >
          <FormattedMessage id="pdi.form.inspection.loyalty_card.customer_refused" />
        </VpCheckbox>
      </VpFormControl>
    </div>
  );
};
export default LoyaltyCardInput;
