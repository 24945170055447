import { getCookie, setCookie } from "./cookie";
import getrefreshToken from "../APIs/getRefreshToken";
import useSWR from "swr";
import logout from "../APIs/logout";
import { clearSessionAndRedirectToLogin } from "./session";

export const setNewToken = async () => {
  const { refresh_token } = getCookie("fedInfo");
  // Token has expired, request a new token here.
  const { refreshedAccessToken, updatedRefreshToken } =
    await getrefreshToken(refresh_token);
  //  If the refresh token has expired  Logout user
  if (!refreshedAccessToken || !updatedRefreshToken) {
    const userInfo = getCookie("userInfo");
    logout(userInfo?.pisri);
  } else {
    setCookie("fedInfo", {
      access_token: refreshedAccessToken,
      refresh_token: updatedRefreshToken,
    });
    return {
      access_token: refreshedAccessToken,
      refresh_token: updatedRefreshToken,
    };
  }
};

let isFetchingData = false;
export const fetchDataWithHeaders = async (
  url,
  headers = {},
  { method = "GET" } = {},
  { revalidateOnReconnect = false, errorRetryCount = 3 } = {},
  body = {}
) => {
  if (isFetchingData) {
    // If a request is already in progress, wait for it to complete
    while (isFetchingData) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // Wait for 100 milliseconds
    }
  }
  // Set the flag to indicate that a request is in progress
  isFetchingData = true;
  try {
    const fedInfo = getCookie("fedInfo");
    if (!fedInfo) clearSessionAndRedirectToLogin();
    const requestOptions = {
      method,
      headers: {
        "X-API-KEY": process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${fedInfo?.access_token}`,
        ...headers,
      },
      revalidateOnReconnect,
      errorRetryCount,
    };
    if (["POST", "PATCH"].includes(method) && body) {
      requestOptions.body = JSON.stringify(body);
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL + url}`,
      requestOptions
    );

    if (!response.ok) {
      if (response.status === 401) {
        const newToken = await setNewToken();
        if (newToken?.access_token && newToken?.refresh_token) {
          isFetchingData = false;
          const data = await fetchDataWithHeaders(
            url,
            headers,
            { method },
            {},
            body
          );
          return data;
        }
      } else {
        isFetchingData = false;
        alert(
          `Oops! Something went wrong. Please contact PDI support. Error ${response?.statusText} `
        );
      }
    } else {
      if (response?.status === 204) {
        return {
          status: response.status,
          body: null,
        };
      }
      // Wait for the response body to be parsed as JSON
      const responseBody = await response.json();
      return {
        status: response.status,
        body: responseBody,
      };
    }
  } finally {
    // Reset the flag to indicate that the request is complete
    isFetchingData = false;
  }
};

export const useAPI = (url) => {
  const { data, error, isLoading } = useSWR(url, (url) =>
    fetchDataWithHeaders(url)
  );
  return { data: data?.body, error, isLoading };
};

/**
 * Fetches data from multiple API endpoints and returns the successful response.
 *
 * @param {string[]} endpoints - The unique identifier for the API call.
 * @returns {Promise<Object>} - A promise that resolves to the successful API response object.
 * @throws {Error} - If all API calls fail.
 */

export const fetchFromMultipleAPIs = async (endpoints) => {
  const apiCalls = endpoints.map((url) => fetchDataWithHeaders(url));
  const results = await Promise.allSettled(apiCalls);
  const successfulResponse = results
    .filter(
      (result) => result?.status === "fulfilled" && result?.value?.body?.length
    )
    .map((result) => result?.value?.body[0]);
  return successfulResponse;
};
