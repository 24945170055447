import { VpIcon } from "@vtmn-play/react";
import { FormattedMessage, useIntl } from "react-intl";
import NavigationBlocks from "../../Common/components/NavigationBlocks";
import FAQ from "../../Common/components/FAQ";
import { useDispatch } from "react-redux";
import { resetRentalQuestionnaire } from "./redux/slices/RentalSlice";
import { useEffect } from "react";
import { resetPDIQuestionnaire } from "../PDI/redux/slices/PDISlice";

const RentalHomepage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  // Delete any questionnaire if present
  useEffect(() => {
    dispatch(resetPDIQuestionnaire());
    dispatch(resetRentalQuestionnaire());
  }, [dispatch]);

  const navigationBlocks = [
    {
      title: <FormattedMessage id="rental.home.links.start-inspection" />,
      url: "inspection",
      icon: <VpIcon name="power" size={28} />,
    },
    {
      title: <FormattedMessage id="rental.home.links.resources-title" />,
      url: "resources",
      icon: <VpIcon name="file" size={28} />,
    },
    {
      title: <FormattedMessage id="rental.home.links.discount-code" />,
      url: "discount-code",
      icon: <VpIcon name="funding" size={28} />,
    },
    {
      title: <FormattedMessage id="rental.home.links.stock-adjustment" />,
      url: "stock-adjustment",
      icon: <VpIcon name="barcode-scan" size={28} />,
    },
    {
      title: <FormattedMessage id="rental.home.links.training-title" />,
      url: "training",
      icon: <VpIcon name="award" size={28} />,
    },
    {
      title: <FormattedMessage id="rental.home.links.reporting-title" />,
      url: "reports",
      icon: <VpIcon name="bar-chart" size={28} />,
    },
  ];
  const FAQItems = [
    {
      title: <FormattedMessage id="rental.home.faq.what-is-title" />,
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "rental.home.what-is" }),
          }}
        ></div>
      ),
    },
    {
      title: <FormattedMessage id="rental.home.faq.why-is-important-title" />,
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "rental.home.why-is-important" }),
          }}
        ></div>
      ),
    },
    {
      title: <FormattedMessage id="rental.home.faq.who-for-title" />,
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "rental.home.who-for" }),
          }}
        ></div>
      ),
    },
  ];
  return (
    <>
      <h1 className="vtmn-px-4  vtmn-text-xl vtmn-font-bold vtmn-text-center vtmn-text-grey-dark-1 ">
        <FormattedMessage id="rental.app-hero-title" />
      </h1>
      <h6 className="vtmn-px-4 vtmn-text-md vtmn-text-center vtmn-text-grey-dark-3 ">
        <FormattedMessage id="rental.app-sub-title" />
      </h6>
      <NavigationBlocks blocks={navigationBlocks} />
      <FAQ accordionItems={FAQItems}></FAQ>
    </>
  );
};
export default RentalHomepage;
