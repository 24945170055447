import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { VtmnNavbar } from "@vtmn/react";
import { VpIcon } from "@vtmn-play/react";
import Headroom from "react-headroom";
import { DecathlonLogo } from "../../../Static/images/icons";

const Header = ({ isQualityControlHomePage }) => {
  let navigate = useNavigate();
  return (
    <Headroom>
      <div className="vtmn-pb-3">
        <VtmnNavbar
          logo={DecathlonLogo}
          leftNav={
            <button
              onClick={() => {
                isQualityControlHomePage ? navigate("/") : navigate(-1);
              }}
            >
              <VpIcon name="arrow-left" />
            </button>
          }
          rightNav={
            <nav>
              <NavLink to="/home">
                <VpIcon name="home" />
              </NavLink>
            </nav>
          }
        ></VtmnNavbar>
      </div>
    </Headroom>
  );
};
export default Header;
