import { jwtDecode } from "jwt-decode";
import { clearSessionAndRedirectToLogin } from "../utils/session";
export default async function getToken(code) {
  try {
    let urlEncoded = new URLSearchParams();
    urlEncoded.append("grant_type", "authorization_code");
    urlEncoded.append("client_id", process.env.REACT_APP_FED_CLIENT_ID);
    urlEncoded.append("code", code);
    urlEncoded.append("redirect_uri", process.env.REACT_APP_FED_REDIRECT_URL);
    const { id_token, access_token, refresh_token } = await (
      await fetch(`${process.env.REACT_APP_FED_BASEURL}/as/token.oauth2`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Basic ${btoa(
            process.env.REACT_APP_FED_CLIENT_ID +
              ":" +
              process.env.REACT_APP_FED_CLIENT_SECRET,
          )}`,
        },
        body: urlEncoded,
        redirect: "follow",
      })
    ).json();

    if (!id_token || !access_token || !refresh_token) {
      clearSessionAndRedirectToLogin();
    }
    const {
      uid,
      uuid,
      displayName,
      mail,
      "pi.sri": pisri,
    } = jwtDecode(id_token);
    // The format of the displayName from FEDID is "LASTNAME Firstname".
    // We are reversing the string and capitalizing, which returns Firstname Lastname
    const formattedDisplayName = displayName
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .reverse()
      .join(" ");
    return {
      access_token,
      refresh_token,
      uid,
      uuid,
      displayName: formattedDisplayName,
      mail,
      pisri,
    };
  } catch (error) {
    console.error("Error in getToken: ", error);
  }
}
