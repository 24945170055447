import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getToken from "../APIs/getToken";
import createOrUpdateUser from "../APIs/createOrUpdateUser";
import { getCookie, setCookie } from "../utils/cookie";

function Authorise() {
  const fedInfo = getCookie("fedInfo");
  const userInfo = getCookie("userInfo");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  useEffect(() => {
    (async () => {
      try {
        if (!fedInfo || !userInfo) {
          const {
            access_token,
            refresh_token,
            uid,
            uuid,
            displayName,
            mail,
            pisri,
          } = await getToken(code);

          setCookie("fedInfo", {
            access_token,
            refresh_token,
          });
          setCookie("userInfo", {
            pisri,
            uid,
            uuid,
            displayName,
            mail,
          });
          createOrUpdateUser({
            access_token,
            uid,
            uuid,
            displayName,
            mail,
          });
          navigate("/", { replace: true });
        }
      } catch (err) {
        console.log("Error occured when fetching fedInfo", err);
      }
    })();
  }, [fedInfo, userInfo, code, navigate]);
}
export default Authorise;
