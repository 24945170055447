import { useNavigate } from "react-router-dom";
import "./navigationBlocks.css";
import React from "react";
const NavigationBlocks = ({ blocks }) => {
  let navigate = useNavigate();

  return (
    <div className="vtmn-grid vtmn-grid-cols-2 vtmn-gap-2 vtmn-justify-items-center vtmn-my-4 vtmn-px-4 ">
      {blocks.map((item, index) => (
        <div
          key={index}
          className="css__content-block vtmn-flex vtmn-flex-col vtmn-justify-center vtmn-items-center vtmn-cursor-pointer vtmn-shadow-300 vtmn-rounded-200 "
          onClick={(e) => {
            e.preventDefault();
            item?.onBlockClick && item?.onBlockClick();
            item?.url && navigate(item?.url);
          }}
        >
          <span> {item?.icon} </span>
          <span className="vtmn-w-4/5"> {item?.title} </span>
        </div>
      ))}
    </div>
  );
};
export default NavigationBlocks;
