import {
  VtmnList,
  VtmnListItem,
  VtmnListItemStartVisual,
  VtmnListItemText,
  VtmnToggle,
} from "@vtmn/react";
import { VpIconButton } from "@vtmn-play/react";
import "./index.css";
import {
  lifetimeGuarantee,
  threeMonthsSafety,
  twoYearsGuarantee,
} from "../../../../Static/images/icons";

import React, { useState } from "react";
import ProgressBar from "../../../../Common/components/ProgressBar";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setInspectionID } from "../../redux/slices/PDISlice";

import submitQuestionnaire from "../../../../Common/APIs/submitQuestionnaire";

const PDIInspectionGuarantee = ({ navigateToNextStep }) => {
  const dispatch = useDispatch();
  const [warrantyExplained, setWarrantyExplained] = useState(false);
  const { pdi, userStore } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);

  const guaranteeDetails = [
    {
      logo: threeMonthsSafety,
      info: (
        <FormattedMessage id="pdi.form.inspection.guarantee.3-month-satefy-check" />
      ),
    },
    {
      logo: twoYearsGuarantee,
      info: (
        <FormattedMessage id="pdi.form.inspection.guarantee.2-year-warranty" />
      ),
    },
    {
      logo: lifetimeGuarantee,
      info: (
        <FormattedMessage id="pdi.form.inspection.guarantee.lifetime-frame-warranty" />
      ),
    },
  ];

  const submitFinalForm = async () => {
    setLoading(true);
    const resp = await submitQuestionnaire(userStore, pdi);
    if (resp?.status === 200) {
      dispatch(setInspectionID(resp?.body?.id));
      setLoading(false);
      navigateToNextStep();
    } else {
      setLoading(false);
      alert("Error submitting the form. Please restart or contact PDI support");
    }
  };

  return (
    <div className="vtmn-w-full vtmn-px-3 vtmn-my-3">
      <h1 className="vtmn-font-bold vtmn-text-2xl">
        <FormattedMessage id="pdi.form.inspection.title" />
      </h1>
      <div className="vtmn-font-bold">
        <FormattedMessage id="pdi.form.inspection.guarantee-subtitle" />
      </div>
      <ProgressBar completion={"66"} />
      <VtmnList className="vtmn-my-3">
        {guaranteeDetails.map((item, index) => (
          <VtmnListItem key={index} className="vtmn-my-4">
            <VtmnListItemStartVisual>{item.logo}</VtmnListItemStartVisual>
            <VtmnListItemText style={{ alignItems: "self-start" }}>
              <span className="vtmn-px-2">{item.info}</span>
              <a
                href="https://www.decathlon.co.uk/landing/warranty-information/_/R-a-a340014v"
                target="_blank"
                rel="noopener noreferrer"
                className="vp-link vtmn-py-3 vtmn-px-2"
                style={{ textDecoration: "underline" }}
              >
                <FormattedMessage id="pdi.form.inspection.guarantee.read-more" />
              </a>
            </VtmnListItemText>
          </VtmnListItem>
        ))}
      </VtmnList>
      <div className="vtmn-flex vtmn-items-center">
        <span className="vtmn-font-bold vtmn-flex-1">
          <FormattedMessage id="pdi.form.inspection.question.guarantee" />
        </span>
        <VtmnToggle
          identifier="warranty-toggle"
          onChange={() => {
            setWarrantyExplained(!warrantyExplained);
          }}
          checked={warrantyExplained}
          size="medium"
        />
      </div>

      <div className="vtmn-py-2 vtmn-mt-5">
        <VpIconButton
          className="vp-button vtmn-btn_size--stretched"
          variant="primary"
          onClick={() => submitFinalForm()}
          disabled={!warrantyExplained || loading}
        >
          <FormattedMessage id="pdi.nav.submit" />
        </VpIconButton>
      </div>
    </div>
  );
};

export default PDIInspectionGuarantee;
