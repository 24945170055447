// Object.freeze(AppType); ensures the object cannot be modified, providing immutability similar to an enum.
export const AppType = Object.freeze({
  pdi: "PDI",
  rental: "RENTAL",
  secondlife: "SECOND_LIFE",
  workshop: "WORKSHOP",
});
//  AppType GET API sends back the IDs saved in DB (Based on seed). To make sure that we have the correct mapping, we are refering this mapper
export const AppTypeIDMappers = Object.freeze({
  1: "PDI",
  2: "RENTAL",
  3: "SECOND_LIFE",
  4: "WORKSHOP",
});

export const PurchaseChannels = Object.freeze({
  online: "ONLINE_ORDER",
  instore: "IN_STORE",
  cnc: "CLICK_COLLECT",
  circular: "CIRCULAR_HUB",
  physical: "PHYSICAL_INSPECTION",
});

//  PurchaseChannels GET API sends back the IDs saved in DB (Based on seed). To make sure that we have the correct mapping, we are refering this mapper
export const PurchaseChannelsIDMappers = Object.freeze({
  1: "IN_STORE",
  2: "CLICK_COLLECT",
  3: "CIRCULAR_HUB",
  4: "ONLINE_ORDER",
  5: "PHYSICAL_INSPECTION",
});

export const CategoryIDMapper = Object.freeze({
  1: "e-bike",
  2: "bike",
  3: "kayak",
  4: "sup",
  5: "windsurf",
  6: "tribord",
  7: "watersport",
  8: "tent",
  9: "airtent",
  10: "poletent",
  11: "rooftent",
});

// NavigationBlocksMapper is a global maping object which is used to render various navigational blocks based on App Type, purchase channel and category
// For instance, if we need meta for a AppType (Eg:PDI)", "NavigationBlocksMapper["PDI"]?.meta" will give us all the info to dynamically render the blocks
// If we need meta for a particular purchase channel, NavigationBlocksMapper[activeAppType]?.channels?.[purchase_channel_name]?.meta will fetch info to dynamically render the blocks
const CategoryBlockMap = Object.freeze({
  kayak: {
    meta: {
      phraseKey: "rental.inspection.category.kayak-title",
      redirectUrl: "questions",
      vpIcon: "kayak",
      blockClickParams: { productCategory: "kayak" },
    },
  },
  sup: {
    meta: {
      phraseKey: "rental.inspection.category.stand-up-paddle-title",
      redirectUrl: "questions",
      vpIcon: "SUP",
      blockClickParams: { productCategory: "sup" },
    },
  },
  windsurf: {
    meta: {
      phraseKey: "rental.inspection.category.windsurf-title",
      redirectUrl: "questions",
      vpIcon: "windsurf",
      blockClickParams: { productCategory: "windsurf" },
    },
  },
  tribord: {
    meta: {
      phraseKey: "rental.inspection.category.tribord-title",
      redirectUrl: "questions",
      vpIcon: "windsurf",
      blockClickParams: { productCategory: "tribord" },
    },
  },
  bike: {
    meta: {
      phraseKey: "rental.inspection.category.bike-title",
      redirectUrl: "questions",
      vpIcon: "cycle",
      blockClickParams: { productCategory: "bike" },
    },
  },
  "e-bike": {
    meta: {
      phraseKey: "rental.inspection.category.ebike-title",
      redirectUrl: "questions",
      vpIcon: "cycle",
      blockClickParams: { productCategory: "e-bike" },
    },
  },
  watersport: {
    meta: {
      phraseKey: "rental.inspection.category.watersport-title",
      redirectUrl: "questions",
      vpIcon: "windsurf",
      blockClickParams: { productCategory: "watersport" },
    },
  },
  tent: {
    meta: {
      phraseKey: "rental.inspection.category.tent-title",
      redirectUrl: "questions",
      vpIcon: "windsurf",
      blockClickParams: { productCategory: "tent" },
    },
  },
  airtent: {
    meta: {
      phraseKey: "rental.inspection.category.airtent-title",
      redirectUrl: "questions",
      vpIcon: "windsurf",
      blockClickParams: { productCategory: "airtent" },
    },
  },
  poletent: {
    meta: {
      phraseKey: "rental.inspection.category.poletent-title",
      redirectUrl: "questions",
      vpIcon: "windsurf",
      blockClickParams: { productCategory: "poletent" },
    },
  },
  rooftent: {
    meta: {
      phraseKey: "rental.inspection.category.rooftent-title",
      redirectUrl: "questions",
      vpIcon: "rooftent",
      blockClickParams: { productCategory: "rooftent" },
    },
  },
});

export const NavigationBlocksMapper = Object.freeze({
  PDI: {
    meta: {
      phraseKey: "pdi.banner",
      redirectUrl: "/pdi",
      vpIcon: "riding",
    },
    channels: {
      PHYSICAL_INSPECTION: {
        meta: {
          phraseKey: "pdi.form.inspection.type.physical",
          redirectUrl: "physical",
          vpIcon: "store",
          blockClickParams: { purchaseChannel: "physical" },
        },
      },
      ONLINE_ORDER: {
        meta: {
          phraseKey: "pdi.form.inspection.type.online",
          redirectUrl: "online",
          vpIcon: "mac",
          blockClickParams: { purchaseChannel: "online" },
        },
      },
    },
  },
  RENTAL: {
    meta: {
      phraseKey: "rental.banner",
      redirectUrl: "/rental",
      vpIcon: "circularity-shop-rental",
    },
    channels: {
      IN_STORE: {
        meta: {
          phraseKey: "rental.inspection.instore.link-title",
          redirectUrl: "instore",
          vpIcon: "store",
          blockClickParams: { purchaseChannel: "instore" },
        },
        category: CategoryBlockMap,
      },
      CLICK_COLLECT: {
        meta: {
          phraseKey: "rental.inspection.cnc.link-title",
          redirectUrl: "cnc",
          vpIcon: "pick-up-store",
          blockClickParams: { purchaseChannel: "cnc" },
        },
        category: CategoryBlockMap,
      },

      CIRCULAR_HUB: {
        meta: {
          phraseKey: "rental.inspection.circular.link-title",
          redirectUrl: "circular",
          vpIcon: "circularity-maintain-repair",
          blockClickParams: { purchaseChannel: "circular" },
        },
        category: CategoryBlockMap,
      },
    },
  },
  SECOND_LIFE: {
    meta: {
      phraseKey: "second-life.banner",
      redirectUrl: "/secondlife",
      vpIcon: "circularity-buy-back",
    },
    channels: {
      CLICK_COLLECT: {
        meta: {},
        category: CategoryBlockMap,
      },
      IN_STORE: {
        meta: {},
        category: CategoryBlockMap,
      },
      CIRCULAR_HUB: {
        meta: {},
        category: CategoryBlockMap,
      },
    },
  },
  WORKSHOP: {
    meta: {
      phraseKey: "workshop.banner",
      redirectUrl: "/workshop",
      vpIcon: "circularity-maintain-repair",
    },
    channels: {
      CLICK_COLLECT: {
        meta: {},
        category: CategoryBlockMap,
      },
      IN_STORE: {
        meta: {},
        category: CategoryBlockMap,
      },
      CIRCULAR_HUB: {
        meta: {},
        category: CategoryBlockMap,
      },
    },
  },
});
