import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

function Login() {
  useEffect(() => {
    const timeout = setTimeout(() => {
      // redirects to an external URL
      window.location.replace(
        `${process.env.REACT_APP_FED_BASEURL}/as/authorization.oauth2?client_id=${process.env.REACT_APP_FED_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_FED_REDIRECT_URL}&scope=openid%20profile%20email`,
      );
    }, 1500);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <p className="vtmn-text-lg vtmn-text-center vtmn-my-9">
      <FormattedMessage id="pdi.app-redirection" />
    </p>
  );
}
export default Login;
