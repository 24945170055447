import { VpButton, VpFormControl, VpInput } from "@vtmn-play/react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setInspectionInfo, setItemInfo } from "../../redux/slices/RentalSlice";
import { fetchDataWithHeaders } from "../../../../Common/utils/APIUtils";
import { useNavigate } from "react-router-dom";

const RentalInspectionInfo = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    itemInfo: { articleID },
  } = useSelector((state) => state.rental);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(setItemInfo({ [name]: value }));
  };
  const handleSubmit = async (articleID) => {
    try {
      const response = await fetchDataWithHeaders(
        `/api/v1/external_products/${articleID}`
      );
      dispatch(setItemInfo({ articleID: articleID }));
      if (response?.body) {
        dispatch(
          setInspectionInfo({
            productCategory: response?.body?.product_category?.category_name,
          })
        );
        navigate("category/questions");
      } else {
        dispatch(
          setInspectionInfo({
            productCategory: "",
          })
        );
        navigate("category");
      }
    } catch (error) {
      console.error(
        `Error fetching data for articleID ${articleID}:`,
        error.message
      );
    }
  };

  return (
    <div className="vtmn-w-full vtmn-px-3 vtmn-my-3">
      <h1 className="vtmn-font-bold vtmn-text-2xl">
        <FormattedMessage id="rental.inspection.article-id.title" />
      </h1>
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="vtmn-relative vtmn-mt-5">
          <VpFormControl>
            <VpFormControl.Label>
              <FormattedMessage id="rental.inspection.form.article-id.title" />
            </VpFormControl.Label>
            <VpInput
              className={`vtmn-w-full`}
              placeholder="eg: 2126071"
              name="articleID"
              onChange={handleInputChange}
              value={articleID}
            />
            <VpFormControl.Helper>
              <FormattedMessage id="rental.inspection.form.article-id.helper-text" />
            </VpFormControl.Helper>
          </VpFormControl>
        </div>

        <div className="vtmn-py-2 vtmn-mt-5">
          <VpButton
            className="vp-button vtmn-btn_size--stretched"
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(articleID);
            }}
            disabled={!articleID?.trim()}
          >
            <FormattedMessage id="pdi.nav.submit" />
          </VpButton>
        </div>
      </form>
    </div>
  );
};

export default RentalInspectionInfo;
