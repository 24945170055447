import React from "react";
import { Link } from "react-router-dom";

const Reporting = () => {
  return (
    <ol
      style={{
        fontSize: "large",
        listStyle: "inside",
        margin: "5px 40px",
        padding: "5px",
        lineHeight: "35px",
      }}
    >
      <li style={{ color: "#3643ba" }}>
        <Link
          to="https://lookerstudio.google.com/u/0/reporting/cc35c79d-5f7d-4b48-b636-ea0d34dbe4aa/page/p_u0mx0bsqvc?params=%7B%22df412%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580SM-Store%2520Manager%22%7D"
          target="_blank"
        >
          Growth Platform
        </Link>
      </li>
    </ol>
  );
};
export default Reporting;
