import RentalBikeInspectionPrelist from "../../../Apps/Rental/components/BikeInspectionPreList";
import RentalCircularInspection from "../../../Apps/Rental/components/CircularInspection";
import RentalInspectionComplete from "../../../Apps/Rental/components/InspectionComplete";
import RentalInspectionQuestionnaireLayout from "../../../Apps/Rental/components/InspectionQuestionnaire";
import PDIInspectionQuestionnaireLayout from "../../../Apps/PDI/components/InspectionQuestionnaire";
import PDIInspectionComplete from "../../../Apps/PDI/components/InspectionComplete";
import PDIInspectionPreList from "../../../Apps/PDI/components/InspectionPreList/";
import PDIInspectionGuarantee from "../../../Apps/PDI/components/InspectionGuarantee";

export const QuestionsStepsMap = Object.freeze({
  PDI: {
    channels: {
      PHYSICAL_INSPECTION: {
        category: {
          bike: [
            PDIInspectionPreList,
            PDIInspectionQuestionnaireLayout,
            PDIInspectionGuarantee,
            PDIInspectionComplete,
          ],
          "e-bike": [
            PDIInspectionPreList,
            PDIInspectionQuestionnaireLayout,
            PDIInspectionGuarantee,
            PDIInspectionComplete,
          ],
        },
      },
      ONLINE_ORDER: {
        category: {
          bike: [
            PDIInspectionPreList,
            PDIInspectionQuestionnaireLayout,
            PDIInspectionGuarantee,
            PDIInspectionComplete,
          ],
          "e-bike": [
            PDIInspectionPreList,
            PDIInspectionQuestionnaireLayout,
            PDIInspectionGuarantee,
            PDIInspectionComplete,
          ],
        },
      },
    },
  },
  RENTAL: {
    channels: {
      IN_STORE: {
        category: {
          rooftent: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          kayak: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          sup: [RentalInspectionQuestionnaireLayout, RentalInspectionComplete],
          windsurf: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          tribord: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          watersport: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          tent: [RentalInspectionQuestionnaireLayout, RentalInspectionComplete],
          airtent: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          poletent: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          bike: [
            RentalBikeInspectionPrelist,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          "e-bike": [
            RentalBikeInspectionPrelist,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
        },
      },
      CLICK_COLLECT: {
        category: {
          rooftent: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          kayak: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          sup: [RentalInspectionQuestionnaireLayout, RentalInspectionComplete],
          windsurf: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          tribord: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          watersport: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          tent: [RentalInspectionQuestionnaireLayout, RentalInspectionComplete],
          airtent: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          poletent: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          bike: [
            RentalBikeInspectionPrelist,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          "e-bike": [
            RentalBikeInspectionPrelist,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
        },
      },
      CIRCULAR_HUB: {
        category: {
          rooftent: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          kayak: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          sup: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          windsurf: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          tribord: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          watersport: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          tent: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          airtent: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          poletent: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          bike: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          "e-bike": [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
        },
      },
    },
  },
  SECOND_LIFE: {
    channels: {
      IN_STORE: {
        category: {
          rooftent: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          kayak: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          sup: [RentalInspectionQuestionnaireLayout, RentalInspectionComplete],
          windsurf: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          tribord: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          bike: [
            RentalBikeInspectionPrelist,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          "e-bike": [
            RentalBikeInspectionPrelist,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
        },
      },
      CLICK_COLLECT: {
        category: {
          rooftent: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          kayak: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          sup: [RentalInspectionQuestionnaireLayout, RentalInspectionComplete],
          windsurf: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          tribord: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          bike: [
            RentalInspectionQuestionnaireLayout,
            RentalBikeInspectionPrelist,
            RentalInspectionComplete,
          ],
          "e-bike": [
            RentalInspectionQuestionnaireLayout,
            RentalBikeInspectionPrelist,
            RentalInspectionComplete,
          ],
        },
      },
      CIRCULAR_HUB: {
        category: {
          rooftent: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          kayak: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          sup: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          windsurf: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          tribord: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          bike: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,

            RentalInspectionComplete,
          ],
          "e-bike": [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
        },
      },
    },
  },
  WORKSHOP: {
    channels: {
      IN_STORE: {
        category: {
          rooftent: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          kayak: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          sup: [RentalInspectionQuestionnaireLayout, RentalInspectionComplete],
          windsurf: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          tribord: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          bike: [
            RentalBikeInspectionPrelist,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          "e-bike": [
            RentalBikeInspectionPrelist,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
        },
      },
      CLICK_COLLECT: {
        category: {
          rooftent: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          kayak: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          sup: [RentalInspectionQuestionnaireLayout, RentalInspectionComplete],
          windsurf: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          tribord: [
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          bike: [
            RentalInspectionQuestionnaireLayout,
            RentalBikeInspectionPrelist,
            RentalInspectionComplete,
          ],
          "e-bike": [
            RentalInspectionQuestionnaireLayout,
            RentalBikeInspectionPrelist,
            RentalInspectionComplete,
          ],
        },
      },
      CIRCULAR_HUB: {
        category: {
          rooftent: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          kayak: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          sup: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          windsurf: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          tribord: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
          bike: [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,

            RentalInspectionComplete,
          ],
          "e-bike": [
            RentalCircularInspection,
            RentalInspectionQuestionnaireLayout,
            RentalInspectionComplete,
          ],
        },
      },
    },
  },
});
