import { useDispatch, useSelector } from "react-redux";
import Questionnaire from "../../../../Common/components/Questionnaire";
import { useAPI } from "../../../../Common/utils/APIUtils";
import {
  addRentalResponse,
  removeRentalResponse,
} from "../../redux/slices/RentalSlice";
import submitQuestionnaire from "../../../../Common/APIs/submitQuestionnaire";
import { useState } from "react";

const RentalInspectionQuestionnaireLayout = ({ navigateToNextStep }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { rental, userStore } = useSelector((state) => state);
  const dispatch = useDispatch();

  const { data, error, isLoading } = useAPI(
    `/api/v1/questions?purchase_channel=${rental?.inspectionInfo?.purchaseChannel}&inspection_type=${userStore?.activeAppType}&category=${rental?.inspectionInfo?.productCategory}`
  );

  const handleInputChange = (item, userResponse) => {
    const { created_at, group, updated_at, ...rest } = item;
    if (
      (item?.acceptable_response_options?.text ||
        item?.acceptable_response_options?.calendar) &&
      !userResponse?.notes?.trim()?.length
    ) {
      dispatch(removeRentalResponse(item?.store_product_question_id));
      return;
    }
    dispatch(addRentalResponse({ ...rest, userResponse }));
  };

  const submitHandler = async () => {
    setIsSubmitting(true);
    const resp = await submitQuestionnaire(userStore, rental);
    if (resp?.status === 200) {
      setIsSubmitting(false);
      navigateToNextStep();
    } else {
      setIsSubmitting(false);
      alert("Error submitting the form. Please restart or contact PDI support");
    }
  };

  return (
    <Questionnaire
      navigateToNextStep={navigateToNextStep}
      handleInputChange={handleInputChange}
      questionnaireResponse={rental?.responses}
      data={data}
      error={error}
      isLoading={isLoading}
      onSubmit={submitHandler}
      isSubmitting={isSubmitting}
      activeAppType={userStore?.activeAppType}
    />
  );
};
export default RentalInspectionQuestionnaireLayout;
