export default async function getrefreshToken(refresh_token) {
  const refreshTokenUrlencoded = new URLSearchParams();
  refreshTokenUrlencoded.append("grant_type", "refresh_token");
  refreshTokenUrlencoded.append("refresh_token", refresh_token);
  const data = await (
    await fetch(`${process.env.REACT_APP_FED_BASEURL}/as/token.oauth2`, {
      method: "POST",
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${btoa(
          process.env.REACT_APP_FED_CLIENT_ID +
            ":" +
            process.env.REACT_APP_FED_CLIENT_SECRET,
        )}`,
      },
      body: refreshTokenUrlencoded,
    })
  ).json();
  const {
    access_token: refreshedAccessToken,
    refresh_token: updatedRefreshToken,
  } = data;
  return {
    refreshedAccessToken,
    updatedRefreshToken,
  };
}
