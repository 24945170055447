import React from "react";
import { NavLink } from "react-router-dom";
import { VtmnNavbar } from "@vtmn/react";
import Headroom from "react-headroom";
import LanguageSelector from "./languageSelector";
import { DecathlonLogo } from "../../../Static/images/icons";

const Header = () => {
  return (
    <Headroom>
      <div className="vtmn-pb-3">
        <VtmnNavbar
          logo={DecathlonLogo}
          leftNav={
            <nav>
              <NavLink className="vtmn-p-4">{}</NavLink>
            </nav>
          }
          rightNav={<LanguageSelector />}
        ></VtmnNavbar>
      </div>
    </Headroom>
  );
};
export default Header;
