import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  allQuestionsAnswered,
  groupQuestionsByGroupNumber,
  renderFinalQuestions,
} from "./questionnaireUtils";
import {
  VtmnList,
  VtmnListItem,
  VtmnListItemEndAction,
  VtmnListItemText,
} from "@vtmn/react";
import { VpButton } from "@vtmn-play/react";
import "./index.css";
import { FormattedMessage } from "react-intl";
import ProgressBar from "../ProgressBar";
import { handleError, handleLoading } from "../../utils/helpers";
import ImageHandler from "../../utils/imageUtils";

const GroupedQuestionList = ({
  groupNumber,
  currentGroupQuestions,
  currentGroupName,
  isVisible,
  handleInputChange,
  allQuestionsAnswered,
  changeGroup,
  totalGroups,
  questionnaireResponse,
  allQuestions,
  isSubmitting,
  activeAppType,
}) => {
  const isLastGroup = parseInt(groupNumber) === parseInt(totalGroups);
  return (
    isVisible && (
      <>
        <VtmnList divider={"true"} key={groupNumber} className="vtmn-my-3">
          <h4 className="vtmn-capitalize  vtmn-text-md vtmn-text-center vtmn-font-bold">
            <FormattedMessage id={currentGroupName} />
          </h4>
          <VtmnListItem>
            <VtmnListItemText className="questionniare-question"></VtmnListItemText>
            <VtmnListItemEndAction className="questionniare-options ">
              <span className="vtmn-mx-1 ">
                <FormattedMessage
                  id={`${activeAppType?.toLowerCase()}.form.inspection.check-complete`}
                />
              </span>
              <span className="vtmn-mx-1 ">
                <FormattedMessage
                  id={`${activeAppType?.toLowerCase()}.form.inspection.check-na`}
                />
              </span>
            </VtmnListItemEndAction>
          </VtmnListItem>
          {currentGroupQuestions.map((item, _indx) =>
            renderFinalQuestions(item, questionnaireResponse, handleInputChange)
          )}
        </VtmnList>
        {isLastGroup && ImageHandler()}
        <div className="vtmn-py-2">
          <VpButton
            className="vp-button vtmn-btn_size--stretched"
            variant="primary"
            disabled={
              !allQuestionsAnswered(
                currentGroupQuestions,
                questionnaireResponse,
                totalGroups,
                groupNumber,
                allQuestions
              ) || isSubmitting
            }
            onClick={() => changeGroup(totalGroups, parseInt(groupNumber))}
          >
            <FormattedMessage id="pdi.nav.continue" />
          </VpButton>
        </div>
      </>
    )
  );
};

const Questionnaire = ({
  navigateToNextStep,
  handleInputChange,
  data,
  error,
  isLoading,
  questionnaireResponse,
  onSubmit,
  isSubmitting,
  activeAppType,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeGroupNumber, setActiveGroupNumber] = useState();
  const [groupedQuestions, setGroupedQuestions] = useState({});

  useEffect(() => {
    if (!isLoading && data) {
      let questionnaireGrouping = groupQuestionsByGroupNumber(data?.items);
      setGroupedQuestions(questionnaireGrouping);
      const totalGroups = Object.keys(questionnaireGrouping).length;
      const param = new URLSearchParams(location?.search);
      const group = parseInt(param.get("group") || 1);
      if (group > totalGroups) {
        setActiveGroupNumber(1);
      } else {
        setActiveGroupNumber(group);
      }
    }
  }, [data, isLoading, location]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [activeGroupNumber]);

  // The below function changes the group  the groups based on interaction.
  // Also, if the activeGroupNumber is more than total groups, then navigate to next page
  const changeGroup = (totalGroups, activeGroup) => {
    const param = new URLSearchParams(location?.search);
    if (activeGroup >= totalGroups) {
      const step = parseInt(param.get("step") || 0) + 1;
      param.set("step", step);
      if (onSubmit) {
        onSubmit();
      } else {
        navigateToNextStep();
      }
    } else {
      setActiveGroupNumber(activeGroup + 1);
      param.set("group", activeGroup + 1);
      navigate({ search: param.toString() });
    }
  };

  if (!data && isLoading) return handleLoading();
  if (!data && error) return handleError();

  if (data) {
    return (
      <div className="vtmn-w-full vtmn-px-3 vtmn-my-3">
        <h1 className="vtmn-font-bold vtmn-text-2xl">
          <FormattedMessage id="pdi.form.inspection.title" />
        </h1>
        <ProgressBar completion={"33"} />
        {Object.entries(groupedQuestions).map(([groupNumber, questions]) => (
          <GroupedQuestionList
            key={groupNumber}
            groupNumber={groupNumber}
            currentGroupQuestions={questions}
            currentGroupName={[
              ...new Set(questions?.map((question) => question?.group_name)),
            ].join(",")}
            isVisible={parseInt(groupNumber) === parseInt(activeGroupNumber)}
            handleInputChange={handleInputChange}
            allQuestionsAnswered={allQuestionsAnswered}
            changeGroup={changeGroup}
            totalGroups={Object.keys(groupedQuestions).length}
            questionnaireResponse={questionnaireResponse}
            allQuestions={data?.items}
            isSubmitting={isSubmitting}
            activeAppType={activeAppType}
          />
        ))}
      </div>
    );
  }
  return null;
};

export default Questionnaire;
