import { VpButton } from "@vtmn-play/react";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import "./index.css";

import { FormattedMessage } from "react-intl";

import { checkboxCircle } from "../../../../Static/images/icons.js";
import ProgressBar from "../../../../Common/components/ProgressBar/index.jsx";
import { useDispatch } from "react-redux";
import { resetRentalQuestionnaire } from "../../redux/slices/RentalSlice.js";

const RentalInspectionComplete = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetRentalQuestionnaire());
    };
  }, []);

  return (
    <div className="vtmn-w-full vtmn-px-3 vtmn-my-3">
      <h1 className="vtmn-font-bold vtmn-text-2xl">
        <FormattedMessage id="pdi.form.inspection.complete.title" />
      </h1>
      <ProgressBar completion={"100"} />
      <section className="vtmn-my-3">
        <div className="card__items vtmn-flex vtmn-mt-6 vtmn-flex-wrap vtmn-items-center vtmn-justify-center">
          <div
            className="card__item  vtmn-py-2
            vtmn-flex vtmn-flex-col vtmn-items-center vtmn-justify-center vtmn-text-center
            vtmn-w-full vtmn-h-full gt-tablet:vtmn-w-1/2 gt-small-desktop:vtmn-w-1/4
            vtmn-rounded-xl vtmn-bg-white"
          >
            <div className="card__item--image vtmn-object-contain">
              {checkboxCircle}
            </div>
            <h6 className="vtmn-font-bold">
              <FormattedMessage id="pdi.form.inspection.complete.form-saved" />
            </h6>
          </div>
        </div>
      </section>

      <div className="vtmn-py-5">
        <VpButton
          className="vp-button vtmn-btn_size--stretched"
          variant="primary"
          onClick={() => {
            dispatch(resetRentalQuestionnaire());
            navigate("/rental", { replace: true });
          }}
        >
          <FormattedMessage id="pdi.nav.go-home" />
        </VpButton>
        <div className="vtmn-py-3">
          <VpButton
            className="vp-button vtmn-btn_size--stretched"
            variant="secondary"
            onClick={() => {
              dispatch(resetRentalQuestionnaire());
              navigate("/rental/inspection", { replace: true });
            }}
          >
            <FormattedMessage id="pdi.nav.new-inspection" />
          </VpButton>
        </div>
      </div>
    </div>
  );
};

export default RentalInspectionComplete;
