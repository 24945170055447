import React from "react";
import { Link } from "react-router-dom";
const Resources = () => {
  return (
    <ol
      style={{
        fontSize: "large",
        listStyle: "inside",
        margin: "2px 38px",
        lineHeight: "35px",
      }}
    >
      <li style={{ color: "#3643ba" }}>
        <Link
          to="https://docs.google.com/presentation/d/1opr6KldFyhah4eho0NjQfZ2eMxmYv8Mw95dTw2QiFg8/preview?slide=id.g2e65230b4fa_0_0"
          target="_blank"
        >
          Treating Orders - How to slides
        </Link>
      </li>
      <li style={{ color: "#3643ba" }}>
        <Link
          to="https://sites.google.com/decathlon.com/sustainabilityuk/circular-economy/rental/training-information/treating-orders"
          target="_blank"
        >
          Treating Orders - Wiki
        </Link>
      </li>
      <li style={{ color: "#3643ba" }}>
        <Link
          to="https://docs.google.com/presentation/d/1b1hE4nwhGolO7EVNBf_luHfBrKJBMPCibqHHqz3OuIc/preview?slide=id.p"
          target="_blank"
        >
          Rental Quality Control
        </Link>
      </li>
      <li style={{ color: "#3643ba" }}>
        <Link
          to="https://docs.google.com/forms/d/e/1FAIpQLSdm7agQLDOSt8eLMx_ZNUwTR90efUlM6kK4aydCnGaWLkkDxQ/viewform"
          target="_blank"
        >
          Feedback
        </Link>
      </li>
    </ol>
  );
};
export default Resources;
