import { Route, Routes } from "react-router-dom";
import RentalInspectionHomepage from ".";
import NotFound from "../../../../Common/components/NotFound";
import DynamicInspectionStepsRenderer from "../../../../Common/components/DynamicInspectionStepsRenderer";
import InspectionInfo from "../../components/InspectionInfo";
import InspectionCategory from "../../components/InspectionCategory";
import { ValidRouteGuard } from "../../../../Common/authentication/ValidRouteGuard";

const Inspection = (props) => {
  return (
    <Routes>
      <Route index element={<RentalInspectionHomepage />} />
      <Route
        element={
          <ValidRouteGuard
            currentRouteName="purchaseChannel"
            validRoutes={["instore", "cnc", "circular"]}
          />
        }
      >
        <Route path=":purchaseChannel" element={<InspectionInfo />} />
        <Route
          path=":purchaseChannel/category"
          element={<InspectionCategory />}
        />
        <Route
          path=":purchaseChannel/category/questions"
          element={<DynamicInspectionStepsRenderer />}
        />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default Inspection;
