import React from "react";
import { Link } from "react-router-dom";

const Training = () => {
  return (
    <ol
      style={{
        fontSize: "large",
        listStyle: "inside",
        margin: "2px 38px",
        lineHeight: "35px",
      }}
    >
      <li style={{ color: "#3643ba" }}>
        <Link
          to="https://academy.decathlon.com/explore/presential/64ab25f3-bfa7-40e5-a838-ece58776e5a1"
          target="_blank"
        >
          UK PDI Training
        </Link>
      </li>
    </ol>
  );
};
export default Training;
