import { VtmnProgressbar } from "@vtmn/react";
import "./ProgressBar.css";
import React from "react";

const ProgressBar = ({ completion }) => {
  return (
    <div className="vp-progress-bar vtmn-w-full">
      <VtmnProgressbar
        labelId="progress-bar-text"
        loadingText=""
        size="small"
        status="determinate"
        value={completion}
        variant="linear"
      />
    </div>
  );
};

export default ProgressBar;
