import React from "react";
import { Link } from "react-router-dom";
const DiscountCode = () => {
  return (
    <ol
      style={{
        fontSize: "large",
        listStyle: "inside",
        margin: "2px 38px",
        lineHeight: "35px",
      }}
    >
      <li style={{ color: "#3643ba" }}>
        <Link
          to="https://docs.google.com/forms/d/e/1FAIpQLSdZG8ooD_sRbfbhBu-QpmtQ65gBtjD3aWie-7bnf0wpLxfx1A/viewform"
          target="_blank"
        >
          To request a specific discount code
        </Link>
      </li>
      <li style={{ color: "#3643ba" }}>
        <Link
          to="https://sites.google.com/decathlon.com/sustainabilityuk/circular-economy/rental/training-information/discounts-staff-commercial"
          target="_blank"
        >
          For 50% teammate discount
        </Link>
      </li>
      <li style={{ color: "#3643ba" }}>
        <Link
          to="https://sites.google.com/decathlon.com/sustainabilityuk/circular-economy/rental/training-information/workshop-rentals"
          target="_blank"
        >
          For repairs/workshop customer discount
        </Link>
      </li>
    </ol>
  );
};
export default DiscountCode;
