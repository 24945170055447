import { useDispatch, useSelector } from "react-redux";
import { setUserLocale } from "../../../Redux/CommonSlices/userStoreSlice";

const LanguageSelector = () => {
  const dispatch = useDispatch();
  const { userLocale } = useSelector((state) => state.userStore);
  return (
    <select
      name="language"
      defaultValue={userLocale}
      onChange={(e) => {
        dispatch(setUserLocale(e.target.value));
      }}
    >
      <option value="en-GB">GB</option>,<option value="fr-FR">FR</option>,
      <option value="nl-NL">NL</option>,
    </select>
  );
};

export default LanguageSelector;
